export const SET_FOOTER_DATA = ( state, payload ) => {
    state.footer = payload 
}

export const LOADING_FOOTER = ( state, boolean ) => {
    state.loading =  boolean
}

export const ERROR_FOOTER = ( state, message ) => {
    state.error =  message
}