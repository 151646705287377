<template>
    <div class="text_column column  " >
        <carousel  v-if="data" :breakpoints="breakpoints" :settings="settings" class="items-start carousel_dish" >
            <slide v-for="list in data.list" :key="list.title" class="slide border-even">
                <img :src="list.image" alt="">
                <h2>{{ list.title }}</h2>
                <p v-html="list.text" class="sm:mt-3 xs:mt-3 text-18 font-extralight"></p>
            </slide>
             <template #addons >
                <div class="pagination_container  xs:visible sm:visible" :class="{'invisible' : data.list.length <= 3}">
                    <pagination/>
                </div>
            </template>
        </carousel>
    </div>
</template>
<script>
import { Carousel, Slide, Pagination } from 'vue3-carousel';
export default {
    name: 'TextColumn',
    props:['data'],
    components:{
        Carousel,
        Slide,
        Pagination

    },
  setup( props ){
        const settings =  {
            snapAlign: 'center',
        }
        const breakpoints = {
        380: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        700: {
            itemsToShow: props.data.list.length < 3 ? props.data.list.length : 3 ,
            snapAlign: 'center',
        },
        1024: {
            itemsToShow: props.data.list.length < 3 ? props.data.list.length : 3 ,
            snapAlign: 'center',
            },
        }

        return { settings, breakpoints}
  }
}
</script>
<style scoped>
    .text_column{
        @apply xs:p-6 sm:p-6  xs:mt-4 sm:mt-4 md:mt-4 mt-10 md:p-5 xs:pb-0 sm:pb-0 md:pb-0 mb-16 my-5 xs:mb-6 sm:mb-6 xs:my-0 xs:mx-0 md:mx-0 m-auto md:container lg:container xl:container ;
        max-width: 1200px;
    }
    .slide{
        @apply pr-10 pt-6 pb-6 flex flex-col text-left items-start w-4/12 sm:items-center sm:text-center xs:text-center xs:items-center;
    }
    h2{
        @apply text-green text-2xl text-left mb-3 mt-5 sm:text-2xl;
    }
 
    .border-even{
        border-left: solid 1px rgba(0, 0, 0, 0.4);
        @apply px-6
    }
       .border-even:first-child{
        border-left: unset;
        @apply pr-6
    }

       .border-even:last-child{
        border-right: unset;
         @apply pr-6;
    }
    @media only screen and (min-width:321px) and (max-width:767px){
       .column .slide{
            border-left: unset;
            border-right: unset;
        }
    }
    ::deep .pagination_container li button.carousel__pagination-button--active {
        background-color: #d6212e!important;
        width: 80px;
    }
    ::deep  .pagination_container li button {
        background-color: #c4c4c4!important;
        width: 23px;
        height: 16px;
        border-radius: 20px;
    }
</style>