<template>
    <header id="header" class="sm:h-14 md:h-14 flex justify-center xs:justify-start sm:justify-start items-center shadow-md xs:py-2 sm:py-2 xs:px-6 sm:px-6 md:px-6 px-8 flex-row bg-white" :class="{'fixed' : isFixed}">
    
        <!-- <div class="lg:container xl:container 2xl:container flex justify-between xs:w-full sm:w-full md:w-full"> -->
            <div class="container flex justify-between">
            <router-link :to="{ name : 'Home' }" class="flex" custom >
                <a @click="onGotoHome" 
                class="flex justify-center flex-col" 
                alt="ON Home" 
                :href="rutaHome"
                >
                    <h1 class="m-0"> <img src="@/assets/images/logo_ON.png" alt="ON" class="pl-4 xs:pl-0 sm:pl-0" id="ON_logo"> </h1>
                </a>
            </router-link>

<!-- 
            <router-link :to="{ name : 'Home' }" class="flex" custom >
                <a href="/dev/" @click="onGotoHome"  alt="ON Home" class="flex justify-center flex-col">
                    <div>
                        <h1>
                        <img src="@/assets/images/logo_ON.png" alt="ON" class="pl-4 xs:pl-0 sm:pl-0" id="ON_logo"> 
                        </h1>
                    </div>
                </a>
                
            </router-link> -->
            <div class="flex items-center ">
                <on-header-navigation> </on-header-navigation> 
                <button class="absolute right-2 xs:visible md:visible 2xl:hidden lg:hidden xl:hidden xs:mr-4 sm:mr-4 md:mr-4" aria-label="menu" name="menu" @click="setOpenMenu();">
                    <img src="@/assets/images/icon-menu.svg" alt="">
                </button>
                <div class="my-4 ml-4 w-5/12 xl:w-7/12 lg:w-8/12 relative hidden xs:hidden sm:hidden md:hidden lg:hidden" v-if="false">
                    <input type="text" placeholder="Buscar..." class="bg-gray-200 rounded-xl p-2 w-10/12  relative -right-2">
                    <button class="search btn-green btn-small absolute right-0" > 
                        <i class="search"></i>
                    </button>
                </div>
            </div>
            <transition name="toLeft"> 
                <div class="fixed left-0 top-0  bg-darkgray-100 w-9/12 flex flex-col  overflow-auto h-screen xs:visible md:visible 2xl:hidden lg:hidden xl:hidden menu-mobile" v-show="isOpenMenu">
                    <div class="bg-darkgray-50 pt-5 pb-3">
                        <a href="#" class="absolute right-4" alt="ON menu link" name="ON menu"   @click="setOpenMenu();"> 
                            <img src="@/assets/images/icon-close.png" alt="close" class="max-h-5">
                        </a>
                        <router-link :to="{ name : 'Home' }"  class="mx-8 mt-px-7 mb-7 md:mx-16 inline-flex"  @click="setOpenMenu();">
                            <img src="@/assets/images/logo-ON_light.png" alt="ON" width="81">
                        </router-link>
                    </div>
                    <ul class="flex flex-col justify-around items-center w-12/12 text-white pt-5">
                        <li  class="p-3 flex-auto relative font-medium hover:text-white hover:bg-red xs:hover:bg-opacity-0 text-lg rounded-xl w-10/12 my-2" v-for="( { childs, title, destination_route, icon_url, destination_type  } , index) in navbar" :key="index">
                            <a :href="destination_route" @click.prevent="setPageNumber(destination_route)" v-if="destination_type == 'I'" class="flex text-base w-max"  :alt="title" target="_self"  @click="showContent(childs, index)">
                                <img :src="icon_url" :alt="title+'-child'"     class="mr-5 unset-image" v-if="icon_url">
                                {{title}}
                                <img src="@/assets/images/icon-arrow-down-light.svg" class="ml-3" alt="Arrow" v-if="childs.length != 0" >
                            </a>
                            <a :href="destination_route" target="_blank" rel="noreferrer" class="flex text-base w-max"  v-if="destination_type == 'E'" @click="showContent(childs, index)"  :alt="title"> 
                                <img :src="icon_url" :alt="title+'-child'"   v-if="icon_url">
                                {{title}}
                                <img src="@/assets/images/icon-arrow-down-light.svg" class="ml-3" alt="Arrow" v-if="childs.length != 0" :data-index="index">
                            </a>                            
                            <div class="child-container flex flex-col  z-10 nav top-10  mt-2 w-auto" v-if="childs.length != 0" v-show="childIndex == index">
                                <ul class="child px-2">
                                    <li class="flex-auto font-medium hover:text-white hover:bg-red text-base rounded-xl w-full my-4" v-for="({ title, destination_route, destination_type , icon_url }, index) in childs" :key="index" >
                                        <a :href="destination_route" @click.prevent="setPageNumber(destination_route); setOpenMenu();" v-if="destination_type == 'I'"  class="flex items-center"  :alt="title">
                            <img :src="icon_url" class="mr-2" :alt="title" v-if="icon_url">
                                            {{title}}
                                        </a>
                                        <a :href="destination_route" target="_blank" rel="noreferrer"  :alt="title"  class="flex items-center"  v-if="destination_type == 'E'"> 
                            <img :src="icon_url" class="mr-2"  :alt="title"  v-if="icon_url">
                           
                                            {{title}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </transition>
            <transition name="fadeIn">
                <div class="w-full h-full fixed mask left-0 top-0" v-if="isOpenMenu">
                    <div class="bg-black w-full h-full" @click="setOpenMenu(); "></div>
                </div>
            </transition>
        </div>
    </header>
</template>

<script>
import { ref } from '@vue/reactivity'
import { mapGetters, mapState, useStore } from 'vuex'
import HeaderNavigation from './HeaderNavigation.vue'
import { onMounted } from '@vue/runtime-core'
import { useRouter } from 'vue-router'
export default {
    computed: {
        ...mapGetters({
            navbar: 'getNavbarList',
        }),
        ...mapState({
            Navbar: state => state.Navbar,
            isOpenMenu : state => state.Navbar.isOpenMenu 
        })
    },
    components:{
        "on-header-navigation" : HeaderNavigation,
    },
    setup() {
        const isFixed = ref(false)
        const router = useRouter()
        const store = useStore()
        const childIndex = ref()

        // -- INIT ---
        const rutaHome = '/'
        // -- END ---

        store.dispatch( 'callNavbarData' )
        const setPageNumber = ( pageNumber ) =>  {
            store.commit( 'SET_PAGE_NUMBER', pageNumber )
            router.push({ name : 'Home' })
            window.scrollTo(0, 0);
        }
        onMounted(() => {
            window.document.onscroll = () => {
                let navBar = document.getElementById('header');
                isFixed.value  = (window.scrollY > navBar.offsetTop ? true : false );   
            }
        })
        const showChild = ( index ) =>{
            childIndex.value = index
        }
        const showContent = (childs, index) => {
            if(childs.length == 0)
            { 
                closeMenu()
            }
            else showChild( index );
        }
        const closeMenu = () => {
            store.commit('SET_OPEN_MOBILE_MENU' , false)  
            document.documentElement.style.overflow = 'auto'
        }
        const setOpenMenu = () => {
            const isOpenMenu = store.state.Navbar.isOpenMenu
            store.commit('SET_OPEN_MOBILE_MENU', !isOpenMenu)  
            if(isOpenMenu == false){  
                document.documentElement.style.overflow = 'hidden'
            }
            else{
                document.documentElement.style.overflow = 'auto'
            }
        }


        const onGotoHome = () => {
            router.push({ name : "Home" })
        }
        return {isFixed, childIndex, setPageNumber,showChild, setOpenMenu, closeMenu, showContent, onGotoHome,rutaHome}

    },
    created(){
        setTimeout( () =>{
            document.querySelectorAll('#StayFocusd-infobar-links a').forEach( element => { 
                element.setAttribute('href', "#")
            })
        }, 2000)
    }
}
</script>
<style scoped>
    .unset-image{
        max-width: unset;
        height: unset;
    }
    .menu-mobile{
        z-index: 10;
    }   
    .mask {
        z-index:9;
        opacity: 0.6;
    }
    .mask div {
        opacity:0.6
    }
    button {
        height:40px;
    }
    button i.search{
        display: block;
        width:18px;
        height: 18px;
        background-image: url(../assets/images/icon-search.svg);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
    #header.fixed{
        position: fixed;
        z-index: 100;
        width: 100%;
        top: 0;
        left: 0;
        transition: 150ms;
    }
    @media only screen and (min-width: 1281px) and (max-width: 1366px) {
        #ON_logo {
            padding-left: 10;
        }
    }
    @media only screen and (min-width: 992px) and (max-width: 1024px)  {
        #ON_logo {
            padding-left:0px;
        }
    }
    @media screen and (max-width:480px) {
        #ON_logo {
            max-height:30px;
        }
    }
</style>