<template>
<div class="modal-container" >
    <div class="modal">
        <div class="modal-header">
            <a href="#" class="close" @click.prevent="$emit('onClose')">
               <img src="@/assets/images/icon-close.png" alt="close">
            </a>
        </div>
        <div class="modal-body">
            <p class="text-2xl font-bold text-center">¡Uju!</p>
            <img :src="barcode.qr_code" alt="QR" class="mx-auto my-6">
            <p class="text-center text-lg font-bold">  {{ barcode.referencia }}</p>
            <p class="text-center underline mt-4 text-xs">Una vez realizado el pago tu paquete quedara habilitado</p>
            <p class="text-center  mt-4 text-lg font-bold"> {{barCodeReference.dataObj.sms }}</p>
        </div>
        <div class="modal-footer">
            <button class="btn btn-black" @click.prevent="$emit('onClose')"> Revisar </button>
        </div>
        <a href="https://secciones.dish.com.mx/avisos-de-privacidad.html" target="_blank" class="underline text-center block my-2" > Al continuar aceptas el Aviso de Privacidad </a>
    </div>
    </div>
</template>
<script>
export default {
    props:['onShow', 'message' , 'barcode', 'barCodeReference' ],
    emits:['onClose'],
    setup() {
        
    },
}
</script>
<style scoped>
    .modal-header{
        @apply relative;
    }

    .modal-footer{
        @apply flex align-middle justify-center;
    }
    .modal-body{
        @apply px-5 pt-6 pb-3 text-center;
    }
    .modal{
        @apply absolute left-0 top-0 bg-white rounded-2xl px-5 pb-6;
        width: 590px;
        left: 50%;
        transform: translate(-50%, 50%);
    }
    .modal-container{
        @apply  w-full h-screen fixed top-0 left-0 z-0; 
        background: rgba(0, 0, 0, 0.5);
        content:"";
        z-index: 9999999;
    }
    .modal-footer .btn{
        @apply mx-2 my-2;
    }
    @media screen and (max-width:767px) {
        .modal {
            width:calc(100% - 40px)
        }
        .close {
            left:calc(50% - 1.5rem)
        }
    }
</style>