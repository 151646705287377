export const saveUserInformation = async ( {commit} , data ) => {
    try{
        commit( 'SET_USER_DATA', data )
    } catch( err ){
        commit( 'LOADING_USER', false )
        commit( 'ERROR_USER', err.message )
    }
}

export const saveUserInformationContact = async ( {commit }, params ) => {
    try{
        commit( 'SET_USER_CONTACT_ADDITIONAL_DETAILS', params )
    } catch( err ){
        commit( 'SET_ERROR', err.message )
    } finally {
        commit( 'SET_LOADING', false )
    }
}
