<template>

    <div class="p-3 page_registro _registro xs:p-6 sm:p-6"  v-if="information">
        <RegistroLoader v-if="loading" />
        <template v-else>
            <a href="#" class="underline text-red" v-if="activeStep != 1" @click.prevent="onValidate( activeStep - 1) "> Regresar </a>
            <h3 class="uppercase text-2xl" v-if="!showSuccess">DÉJANOS TUS DATOS PARA CONTACTARTE</h3>
            <h3 class="uppercase text-2xl" v-if="showSuccess">GRACIAS POR TU CONTRATACIÓN</h3>

            <div class="separator_form"></div>
            <div class="step" v-show="activeStep == 1 && showSuccess == false">
                  <Form v-slot="{ errors }" @submit="onSendSMS" ref="stepSendSMS">
                    <div class="my-5">
                        <label for="telefono" class="font-medium" > Celular* (10 dígitos) </label>
                        <Field name="telefono"  v-maska="'#### - #### - ##'" type="text" :rules="isRequiredTenDigits" :disabled="(isCodeInvalid ? true : false )" v-model="telefono_primary" aria-placeholder="000 - 000 - 00" placeholder="0000 - 0000 - 00" class="text-gray-400" />
                        <span  class="text-red2">{{ errors.telefono }}</span>
                        <div class="flex flex-wrap items-center xs:justify-center sm:justify-center xs:flex-col sm:flex-col" v-show="!isCodeInvalid" >
                            <button type="submit" class="btn-green my-2  mr-5 xs:h-0 sm:h-0 xs:mt-0 sm:mt-0 xs:p-0 sm:p-0 w-5/12 xs:w-7/12 sm:w-7/12 btn_enviar xs:mr-0 sm:mr-0 xs:hidden sm:hidden md:visible lg:visible xl:visible 2xl:visible"  @click="onSendSMS"> Enviar Código</button>
                            <button type="submit" class="btn-green-border my-2 mr-5 w-5/12 xs:w-7/12 sm:w-7/12 btn_enviar xs:mr-0 sm:mr-0 xs:visible sm:visible md:hidden lg:hidden xl:hidden 2xl:hidden"  @click="onSendSMS"> Enviar Código</button>
                            <p class="flex-1 xs:text-center sm:text-center" v-show="hasSMSCode">
                                ¿No recibiste el código?<br>
                                <button type="submit" href="#" class="font-semibold underline xs:invisible sm:invisible " >Reenvío de código </button>
                            </p>
                        </div>
                    </div> 
                </Form>
                <Form v-slot="{ errors }" class="mt-3" v-show="hasSMSCode && isCodeInvalid == null" @submit="onValidateSMSCode" ref="stepValidateCode">
                    <div class="my-5">
                        <div>
                            <label for="cell" class="font-medium w-full"  > Código de Validación* </label>
                        </div>
                        <div class="flex items-center">
                            <div class="w-4/12">
                                <Field name="code" v-model="smsCode" v-maska="'XXXXXX'" type="text" :rules="isRequiredMaxSix" aria-placeholder="" placeholder=""  />
                            </div>
                            <div class="w-6/12 p-5">
                            <button class="text-black font-semibold underline w-full text-left btn_validar" type="submit">Validar Código</button>
                            </div>
                        </div> 
                        <span class="text-red2">{{ errors.code }}</span>                   
                    </div>
                </Form>
            </div>
            <div v-show="hasSMSCode && isCodeInvalid && showSuccess == false">
             
                <Form v-slot="{ errors }" @submit="onSendCustomerServiceForm" ref="stepInformation" :validation-schema="schema" >
                    <div class="my-5">
                        <label for="fullname"> Nombre Completo* </label>
                        <Field name="fullname"  pattern="^[a-zA-Z]+$" v-on:keypress="isLetter" type="text" :rules="isRequiredLettersOnly" aria-placeholder="Juan Pérez" v-model="fullname" placeholder="Juan Pérez"/>
                        <span class="text-red2">{{ errors.fullname }}</span>
                    </div>
                    <div class="my-5">
                        <label for="email" class="font-medium" > Correo electrónico* </label>
                        <Field name="email" pattern="^[a-zA-Z]+$" type="text"  :rules="isRequiredEmail" aria-placeholder="me@mail.com" placeholder="me@mail.com" v-model="email"  />
                        <span class="text-red2">{{ errors.email }}</span>
                    </div>
                    <div class="my-5">
                        <label for="direction" class="font-medium" > Dirección de Instalación* </label>
                        <Field name="direction" pattern="^[a-zA-Z]+$"  type="text" :rules="isRequired" aria-placeholder="Av Arteaga y Salazar 58, Contadero" placeholder="Av Arteaga y Salazar 58, Contadero" v-model="direction"/>
                        <span class="text-red2">{{ errors.direction }}</span>
                    </div>
                     <div class="my-5">
                        <label for="cp" class="font-medium" > C.P.* </label>
                        <Field name="cp"  type="text" :rules="isRequired" aria-placeholder="05500" placeholder="05500" v-maska="'#####'" v-model="postalCode"/>
                        <span class="text-red2">{{ errors.cp }}</span>
                    </div> 
                    <div v-if="false"> 
                    <div class="my-5">
                        <p class="font-bold my-5"> ¿Te interesa otro de nuestros los servicios? </p>
                        <div class="bg-gray-450 p-4 md:w-full pl-6 rounded-full xs:w-full sm:w-full lg:w-full flex items-center " :class="{ 'active':  services == 'telivision' }">
                            <Field name="services" type="radio" value="telivision" class="mr-3 w-10 h-10" v-model="services" />
                            <label for="services" class="font-medium " > Servicio de Televisión
                                <span class="font-thin block text-xs"> Hasta 900 canales de cable y paquetes increíbles a precios especiales </span>
                            </label>
                        </div>
                    </div>
                    <div class="my-5">
                        <div class="bg-gray-450 p-4 md:w-full pl-6 rounded-full xs:w-full sm:w-full lg:w-full flex items-center " :class="{ 'active':  services == 'cellular' }">
                            <Field name="services" type="radio" value="cellular" class="mr-3" v-model="services"/>
                            <label for="services" class="font-medium " > Servicio de Telefonía Celular
                                <span class="font-thin block text-xs"> Hasta 6GB de datos, redes sociales y llamadas ilimiatas a un precio especial </span>
                            </label>
                        </div>
                    </div>
                    <div class="my-5">
                        <div class="bg-gray-450 p-4 md:w-full pl-6 rounded-full xs:w-full sm:w-full lg:w-full flex items-center " :class="{ 'active':  services == 'card' }">
                            <Field name="services" type="radio" value="streaming" class="mr-3" v-model="services"/>
                            <label for="services" class="font-medium " > Servicio de Streaming
                                <span class="font-thin block text-xs"> Las mejores series, peliculas y documentales on demand en calidad HD </span>
                            </label>
                        </div>
                    </div>
                    <div class="my-5">
                        <span class="text-red2">{{ errors.services }}</span>
                    </div>
                    </div>
                    <button class="btn-green mt-5 xs:invisible sm:invisible xs:h-0 sm:h-0 xs:mt-0 sm:mt-0 xs:p-0 sm:p-0 " type="submit" > Siguiente</button>
                </Form>
            </div>
            <div v-show="showSuccess">
                <p>En un lapso de 10 días un técnico asistirá a tu domicilio para instalar/activar tu(s) servicio(s), de acuerdo con lo que hayas contratado.</p>
            </div>
        <div class="xs:visible sm:visible invisible fixed bottom-0 left-0 bg-white w-full ">
            <div class="bottom-nav flex items-center p-5 justify-center step-mobile ">
                <button class="btn-black mr-4" @click.prevent="onShowDetails()" v-if="activeStep == 1"> Ver detalles </button>
                <button class="btn-green" @click.prevent="onValidateSMSCode( {code : smsCode })" v-if="hasSMSCode && isCodeInvalid == null"> Validar Código</button>
                <button class="btn-green" @click.prevent="onSendCustomerServiceForm()" v-if=" hasSMSCode && isCodeInvalid " v-show="!showSuccess"> Siguiente</button>
            </div>
            <p class="text-xs text-center underline mb-5" v-if="isCustomerValid">Al continuar aceptas nuestro <a href="https://secciones.dish.com.mx/avisos-de-privacidad.html" target="_blank" class="font-bold underline"> Aviso de Privacidad </a></p>
        </div> 
        <div v-html="tagImage" class="tagImagePixel"> </div>

        </template>
        <transition name="fade"> 
            <ModalNotification v-show="isShow" @onClose="onClose"/>
        </transition>
        <transition name="fade"> 
            <ModalNotificationInvalid v-show="isCodeInvalid == false " @onClose="onCloseInvalid"/>
        </transition>
        <transition name="fade"> 
            <ModalMap v-if="isShowMap" @onClose="onCloseMap" :productData="selected"/>
        </transition>
         <transition name="fade"> 
            <ModalNotificationInvalidPostalCode v-show="isPostalCodeInvalid" :message="error" @onClose="onCloseSubscriptionError"/>
        </transition>
    </div>
</template>
<script>
import { maska } from 'maska'
import {Form, Field } from 'vee-validate'
import { ref } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import ModalNotification from '@/components/modals/ModalNotification.vue'
import ModalNotificationInvalid from '@/components/modals/ModalNotificationInvalid.vue'
import ModalNotificationInvalidPostalCode from '@/components/modals/ModalNotificationInvalidPostalCode.vue'
import RegistroLoader from '@/components/loaders/Registro.vue'
import ModalMap from '@/components/modals/ModalMap.vue'
import { mapState, useStore } from 'vuex'
import { mapGetters } from 'vuex'
import { checkoutStepOneAlambrico, checkoutStepTwoAlambrico } from '@/pixel/pixel.js'
export default {
    directives: { maska },
    emits:['onShowDetails'],
    computed:{
    ...mapGetters({
            isPostalCodeInvalid  : 'isPostalCodeInvalid',
            isCodeInvalid: 'isCodeInvalid',
            isCustomerValid: 'isCustomerValid',
            error : 'getError',
            information : 'getProductInformation',
        }),
    ...mapState({
            selected : state => state.Products.selected,
            address : state => state.Subscription.geo_address,
            loading : state => state.Subscription.loading
        })
    },
    components: {
        Field,
        Form,
        ModalNotification,
        ModalNotificationInvalid,
        ModalNotificationInvalidPostalCode,
        ModalMap,
        RegistroLoader
    },
     data() {
        return {
        schema: {
          //  services: (value) => {return value ? true : 'Este campo no lo puedes dejar en blanco los servicios' },
            fullname: ( value ) => { return value  ? true: this.isRequiredLettersOnly( value) },
            email: ( value ) => { return value ? true : this.isRequiredEmail( value)  }, 
            direction: ( value ) => { return value ? true : this.isRequired( value) },
            cp: ( value ) => { return value ? true : this.isRequired( value ) }
        }
        }
    },
    setup(props, context ){
        const router = useRouter()
        const store = useStore()
        const activeStep = ref(1)
        const isShow = ref( false )
        const fullname = ref('')
        const hasSMSCode = ref( false ) /** TO DO */
        const isShowMap = ref( false )
        const coloniaList = ref([])       
        const telefono_primary = ref('')
        const email = ref('')
        const direction = ref('')
        const smsCode =  ref('')
        const stepInformation = ref(null)
        const stepSendSMS  = ref(null)
        const stepValidateCode = ref(null)
        const stepSaveContact = ref(null)
        const stepAddress = ref(null)
        const services = ref(null)
        const postalCode = ref('')
        const tagImage = ref('')
        const stepCheckCoverage = store.state.Products.selected.check_cover
        const showSuccess = ref(false)
        const geo_address = ref({
            colonia: "",
            state:null,
            municipio: null,
            ciudad: null,
            exterior: "",
            interior: "",
            postal_code: "",
            calle : "",
            ccolonia : null,
            reference: null
        })
        const geo_disabled = ref({
            colonia: false,
            state:false,
            municipio: false,
            address: false,
            postal_code : false
        })
        const isRequiredEmail = (  ) => {
            const value = email.value
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;            
            const test =   re.test(value.toLowerCase())

               return value ? test ? true : 'El formato de email no es válido' : 'El formato de email no es válido.' 
        }
        const isRequired = ( value ) =>{
                return value ? true :  'Este campo no lo puedes dejar en blanco.';
        }
        const isRequiredMaxSix = ( value ) => {

                if( value ){
                    if( value.length < 6 ) {
                        return 'Must be 6 Alphanumeric characters only'
                    } else if( /^[a-z\d\-_\s]+$/i.test(value) ) {
                        return true
                    } else{
                        return 'Must be Alphanumeric characters only'
                    }
                } else {
                    return 'Este campo no lo puedes dejar en blanco.';
                } 
        }
        const isRequiredTenDigits = ( value ) => {
            if( value ){
                console.log( value.replace(/ /g,'').replace(/-/g,'').length )
                if( value.replace(/ /g,'').replace(/-/g,'').length == 10 ){
                    if( value.replace(/ /g,'').replace(/-/g,'').length  < 10 ) {
                        return "Tu número debe tener 10 números."
                    } else {
                        return true
                    }
                } else {
                    return "Tu número debe tener 10 números."
                }
            }
            else {
                return value ? true : 'Tu número debe tener 10 números.';
            }
        }
        const isRequiredLettersOnly = (value) => {
            if( /\d/.test(value) ) {
                return "Tu nombre no puede contener números."
                /* eslint-disable-next-line */
            } else if ( /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value)){
                return "Tu nombre no puede contener carcateres especiales."
            } else {
                return value ? true : 'Este campo no lo puedes dejar en blanco.';
            }
        }
        const onValidate = (  step ) => {
            if( step == 4 ){
                router.push('/pago')
            }
            store.commit( 'SET_SMS_VALIDATION' , null)
            activeStep.value = step
        
        }
        const onClose = ( ) => {
            isShow.value = false
        }
         const onCloseSubscriptionError = () => {
            store.commit('SET_ERROR_SUBSCRIPTION', "")
        }
        const onCloseInvalid = () => {
            store.commit('SET_SMS_VALIDATION', null)
        }
       
        const onCloseMap= ( ) => {
            isShowMap.value = false
        }

        const onSaveUserInformation = ( data ) =>{
            store.dispatch('saveUserInformation', data )
            activeStep.value = 2
        }

        const onValidateSMSCode = ( {code} ) => {
            
            
            
            const smscode = code ? code : smsCode.value 
            store.dispatch('sendValidateSMS', { code :smscode } )
        }


        const onSendSMS = ( {telefono} ) =>{
            if( telefono ){
                telefono = telefono.replace(/ /g,'').replace(/-/g,'')
                telefono_primary.value = telefono

                checkoutStepOneAlambrico.tag()
                checkoutStepOneAlambrico.floodlight()
                tagImage.value = checkoutStepOneAlambrico.image

                store.commit('SET_USER_CONTACT_DETAILS', { telefono })
                store.dispatch('sendSMS', telefono)
                hasSMSCode.value = true
            }
        }
    
         const isLetter = (e) => {
            let char = String.fromCharCode(e.keyCode); // Get the character
            if(/^[A-Za-z\s]+$/.test(char)) return true; // Match with regex 
            else e.preventDefault();
        }
        const onShowDetails = () =>{
            context.emit('onShowDetails')
        }
        
        const onSendCustomerServiceForm = async ( ) => {

            console.log("va bien alambrico")
            // const store = useStore()
            // let packageInfo = store.state.Products.selected

            var register = await store.state.Products.information.descripcion
            console.log("regis",register)

            let text = register;
            let result = text.indexOf("SATELITAL");
            console.log("result",result)

            if(result>=0){
                register='satelital'
            }
            else{
                register='alambrico'
            }

            
            console.log("valor a considerar",register)

            const request  =  {
                form : 'contact',
                name : fullname.value, 
                phone : telefono_primary.value.replace(/ /g,'').replace(/-/g,''),
                email : email.value,
                city:   direction.value,
                postal: postalCode.value,
                dish: true,
                tecnologia: register,
                aviso: ''
            }

            console.log(request) 
            
            if( request ){
              
                checkoutStepTwoAlambrico.tag()
                checkoutStepTwoAlambrico.floodlight()
                tagImage.value = checkoutStepTwoAlambrico.image

             const response = await store.dispatch('sendCustomerService', request )
            //  console.log("resp del serv:",response)
             if( response ){
                 showSuccess.value = true
             }
            }
        }

        return {
            activeStep,
            isShow, isLetter, isShowMap , hasSMSCode,  geo_address, telefono_primary, email,
            tagImage,
            fullname,
            services,
            direction,
            postalCode,
            onShowDetails,
            stepInformation,
            stepSendSMS,
            stepSaveContact,
            stepValidateCode,
            stepAddress,
            coloniaList,
            geo_disabled,
            smsCode, 
            onValidate,
            onClose,
            onCloseInvalid,
            onCloseSubscriptionError,
            onCloseMap,
            onSendSMS,
            onValidateSMSCode,
            onSaveUserInformation,
            onSendCustomerServiceForm,
            showSuccess,
            isRequiredLettersOnly,
            isRequiredTenDigits,
            isRequired,
            isRequiredEmail,
            isRequiredMaxSix, 
            stepCheckCoverage}
    }
}
</script>
<style scoped>
.circle-xs{
    padding:5px;
    width: 33px;
    height: 33px;
    @apply md:mr-3 lg:mr-3 xl:mr-3 2xl:mr-3 text-center inline-block;
}
.btn-md{
    padding-left: 1em;
    padding-right: 1em;

}
.page_registro input[type='radio']{
    width:unset
}
/*
button{
    @apply xs:hidden sm:hidden lg:visible xl:visible md:visible;
} */
.btn-light-gray-border:hover {
    @apply text-red2 border-red2
}
.btn_validar:hover {
    @apply text-black
}
@media screen and (max-width:767px){
    .btn_enviar {
        @apply border-2 border-green rounded-full py-5 px-8 font-semibold;
        box-shadow: 0;
        transition: box-shadow 0.2s ease-in-out;
        min-width:160px;
    }
    .separator_form {
        width:80%;
        margin: 0 auto;
        margin-bottom:20px
    }
}
</style>