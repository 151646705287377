<template>
    <div class="lg:container xl:container 2xl:container mx-auto xs:w-full xs:container-none mt-10 grouped-packages-container">
        <div class="flex items-center justify-evenly rounded-full overflow-hidden shadow-light-200 xs:shadow-none sm:shadow-none xs:p-5 sm:p-5 xs:gap-x-3 sm:gap-x-3 xs:hidden sm:hidden md:hidden lg:visible" v-if="data.tabs.length > 1"> 
            <div v-for="( tab, index) in data.tabs " :key="index" class=" w-full text-center flex">
                <button class="text-black text-18 font-normal xs:text-base sm:text-base w-full p-3 rounded-full hover:text-purple-100 xs:font-normal sm:font-normal xs:shadow-light-200" :class="{'bg-purple-50 text-purple-100 rounded-full text-24 xs:font-bold sm:font-bold' : activeParentIndex == index} " @click="setActiveParentIndex( index )" > {{tab.parent.text }}</button>
            </div>
        </div>
        <Carousel :items-to-show="3" :breakpoints="breakpoints"  :wrap-around="false" :settings='settings' class="lg:hidden xl:hidden 2xl:hidden">
            <slide v-for="( tab, index) in data.tabs " :key="index" class=" w-full text-center flex py-5 px-2 md:pl-5">
                <button class="text-black text-18 font-normal xs:text-base sm:text-base w-full p-3 rounded-full hover:text-purple-100 xs:font-normal sm:font-normal xs:shadow-light-200" :class="{'bg-purple-50 text-purple-100 rounded-full text-24 xs:font-bold sm:font-bold' : activeParentIndex == index} " @click="setActiveParentIndex( index )" > {{tab.parent.text }}</button>
            </slide>
            <template #addons>
            <Navigation />
            </template>
        </Carousel>
        <div> 
            <div v-for="( tab, index) in data.tabs " :key="index">
                <div v-if="tab.childs.length > 1" class=" flex xs:px-5 xs:flex-col sm:flex-col md:flex-col sm:px-5 xs:py-3 sm:py-3 items-center justify-evenly overflow-hidden rounded-full shadow-light-200 mt-5 xs:mt-0 sm:mt-0 md:mt-0 xs:shadow-none" v-show="index == activeParentIndex">
                    <div v-for="( child, idx) in tab.childs" :key="idx"  class=" w-full text-center xs:shadow-none sm:shadow-none  xs:hidden sm:hidden lg:block md:block " >
                        <button class="text-black text-18 font-normal xs:rounded-none xs:text-base sm:text-base w-full px-5 py-2 hover:text-purple-100 xs:font-normal sm:font-normal" :class="{'bg-purple-50 rounded-full text-purple-100 text-24 xs:font-bold sm:font-bold' : activeChildIndex == idx} " @click="setActiveChildIndex( idx )"> {{ child.text}}</button>
                    </div>
                    <div v-for="( child, idx) in tab.childs" :key="idx"  class=" w-full  text-center xs:block sm:block md:hidden lg:hidden xl:hidden 2xl:hidden"  v-show="idx <= 1" >
                        <select class="text-black text-18 shadow-light-200 py-5  text-center font-normal rounded-3xl xs:text-sm sm:text-sm w-full px-5" v-model="activeChildIndex" v-show="idx == 1" > 
                               <option :value="idx"   v-for="( child, idx) in tab.childs" :key="idx"  > {{ child.text}} </option>
                        </select>                           
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center mt-10 mb-1 xs:px-6 sm:px-6 md:px-6">
            <p class="text-purple-100 font-semibold text-2xl" v-html="data.list[activeParentIndex][activeChildIndex].description">
            </p>
        </div>
        <div class="list xs:px-6 sm:px-6 md:px-6" v-for="(list, idx ) in data.list " :key="idx" >
            <div class="list-child" v-for="(lc, ix ) in list" :key="ix" >
                <CarouselONPackages :data="{ list: lc.packages, title: data.title }" v-if="idx == activeParentIndex && ix == activeChildIndex"/>
            </div>
        </div>
        <div class="bg-purple-100  text-center rounded-xl  p-2 xs:mx-6 sm:mx-6 md:mx-6">
            <h2 class="text-3xl font-medium m-4 xs:text-22 sm:text-22 md:text-22 xs:leading-7 text-white">
                {{data.list[activeParentIndex][activeChildIndex].featured_text}}
            </h2>
        </div>
        
        <!-- <div class="mt-10 xs:px-6 sm:px-6 md:px-6" v-if="data.list[activeParentIndex][activeChildIndex].instalation_costs !='NO' "> 
            <div class="heading_separator w-2/12 _purple"></div>
            <div class="text-center mb-2">
                <h2>
                    GASTOS DE INSTALACIÓN
                </h2>
            </div>
            <div class="flex pb-4 gap-5 mt-5 xs:flex-col sm:flex-col">
                <div class="bg-gray-100 flex flex-col justify-evenly text-center p-5 rounded-2xl instalacion_container" v-for="index in 3" :key="index">
                    <h3 class="mt-2 font-bold text-24 px-5 leading-7	">Con cargo mensual a tu tarjeta de crédito</h3>
                    <h3  class="text-4xl text-purple-100 mt-0 font-bold mb-0">$2,000</h3>
                    <h3 class="text-sm mt-7 font-medium px-6">En una sola exhibición
Domiciliado a tu tarjeta de crédito o débito</h3>
                </div>
            </div>
        </div> -->
        
        <div class="mt-5 xs:px-6 sm:px-6 md:px-6">
            <p class="text-xs text-purple-200 xs:text-10 sm:text-10" v-html="data.list[activeParentIndex][activeChildIndex].legal">
            
            </p>
        </div>
    </div>
</template>

<style scoped>
    .instalacion_container {
        min-height:274px
    }
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        background:url('../../assets/images/arrow.png') #fff no-repeat;
        background-position: 95% 50%;
    }
</style>
<style>
    .carousel__track {
        width:100%;
        overflow: visible;
    }
</style>

<script>
import CarouselONPackages from '@/components/carousel/product-packages.vue'
import { Carousel, Slide } from 'vue3-carousel';
import { ref } from '@vue/reactivity'
export default {
    name: 'GroupONProductPackages',
    props:['data'],
    components:{
        CarouselONPackages,
        Carousel,
        Slide,
    },
    computed: {
        flexAlign() {
            return this.$parent.$props.data.packages.length < 2
            ? 'justify-center'
            : 'justify-start'
        },
    }, 
    setup( ) {
        const activeGroup =  ref( null )
        const activeParentIndex = ref( 0)
        const activeChildIndex = ref(0)
        const settings =  {
           itemsToScroll: 1,
        }
        const setActiveParentIndex = ( index ) => {
            activeParentIndex.value = index
            activeChildIndex.value = 0
        }
        const setActiveChildIndex = ( index ) => {
            activeChildIndex.value = index
        }
        return { activeGroup, activeParentIndex, setActiveParentIndex, setActiveChildIndex, activeChildIndex, settings }
    },
}
</script>
<style scoped>

    .grouped-packages-container{
        max-width: 1200px !important;
    }
    .packages-container {
        @apply xs:px-0 sm:px-0 md:px-0;
    }

</style>