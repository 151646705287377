import axios from 'axios'
import {endpoints} from '@/api/endpoints.js';
import { environment } from '../../api/environment';
 
export const callPublicToken = async ( {commit,  } ) => {
    try{
        const endpoint = endpoints.token.getPublicToken.endpoint
        commit( 'LOADING_TOKEN', true )
       
        const {data }  = await axios.request({
            url:`${endpoint}`,
            method:'get',
            baseURL : environment.END_POINT_URL,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': environment.TOKEN_APP 
            },
        })
        commit( 'LOADING_TOKEN', false )
        console.log( )
        if( data.res ) {
            commit( 'SET_PUBLIC_TOKEN' , data)
        }
    } catch( err ){
        commit( 'LOADING_TOKEN', false )
        commit( 'ERROR_TOKEN', err.message )
    }
}