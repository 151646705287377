
export const getFooterData = ( state ) => {
    return state.footer
}

export const getFooterTelData = ( state ) => {
    try{
        let dataObj = state.footer.dataObj
        if( dataObj ){
            let data =  dataObj.tel_contact
            return data
        }
    } catch( err) {
        console.log( err )
    }
}

export const getFooterAppLinks = ( state ) => {
    try{
        let dataObj = state.footer.dataObj
        if( dataObj ){
            let data = dataObj.links_app
            return data
        }
    } catch( err) {
        console.log( err )
    }
}

export const getFooterLeftLinks = ( state ) => {
    try{
        let dataObj = state.footer.dataObj
        if( dataObj ){
            let data = dataObj.col_izq_links
            return data
        }
    } catch( err) {
        console.log( err )
    }
}

export const getFooterRightLinks = ( state ) => {
    try{
        let dataObj = state.footer.dataObj
        if( dataObj ){
            let data = dataObj.col_der_links
            return data
        }
    } catch( err) {
        console.log( err )
    }
}

export const getFooterSocialLinks = ( state ) => {
    try{
        let dataObj = state.footer.dataObj
        if( dataObj ){
            let data = dataObj.links_social 
            return data
        }
    } catch( err) {
        console.log( err )
    }
}

export const getFooterLegals  = ( state ) => {
    try{
        let dataObj = state.footer.dataObj
        if( dataObj ){
            
            let data = dataObj.legales
            return data
        }
    } catch( err) {
        console.log( err )
    }
}

export const getFooterService = ( state ) => {
    try{
        let dataObj = state.footer.dataObj
        if( dataObj ){

            let data = dataObj.links_serv_int
            return data
        }
    } catch( err) {
        console.log( err )
    }
}


export const getFooterBannerProm = ( state ) => {
    try{
        let dataObj = state.footer.dataObj
        if( dataObj ){

            let data = dataObj.banner_prom
            return data
        }
    } catch( err) {
        console.log( err )
    }
}