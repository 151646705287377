/*eslint-disable */
/* START ALAMBRICO */


export const landingAlambrico = {
    tag : () => {
        cntrUpTag.track('cntrData', '94df03c0ff7458f6');
    },
    floodlight : () =>{
       
        cntrUpTag.track('cntrData', '94df03c0ff7458f6');
    },
    image: `<img src="https://pixel.sitescout.com/up/94df03c0ff7458f6?cntr_revenue=%pcost=!;&cntr_transactionId=%pord=!;&u1=%pu1=!;&u2=%pu2=!;&u3=%pu3=!;&u4=%pu4=!;&u5=%pu5=!;&cntr_url=%p~oref=!?" width="1" height="1" style="position: absolute; left: -150px;" />`,
    postback : `https://pixel.sitescout.com/up/94df03c0ff7458f6?cntr_postbackAuctionId=[POSTBACK_GOES_HERE]`
}

export const postpagoAlambrico = {
    tag : () => {
        cntrUpTag.track('cntrData', 'b14d3caddf7c440b');
    },
    floodlight : () =>{
        cntrUpTag.track('cntrData', 'b14d3caddf7c440b');
    },
    image: `<img src="https://pixel.sitescout.com/up/b14d3caddf7c440b?cntr_revenue=%pcost=!;&cntr_transactionId=%pord=!;&u1=%pu1=!;&u2=%pu2=!;&u3=%pu3=!;&u4=%pu4=!;&u5=%pu5=!;&cntr_url=%p~oref=!?" width="1" height="1" style="position: absolute; left: -150px;" />`,
    postback : `https://pixel.sitescout.com/up/b14d3caddf7c440b?erp_id=`
}


export const prepagoAlmabrico = {
    tag : () => {
        cntrUpTag.track('cntrData', '68b1353efe8fffe0');
    },
    floodlight : () =>{
        cntrUpTag.track('cntrData', '68b1353efe8fffe0');
    },
    image: `<img src="https://pixel.sitescout.com/up/68b1353efe8fffe0?cntr_revenue=%pcost=!;&cntr_transactionId=%pord=!;&u1=%pu1=!;&u2=%pu2=!;&u3=%pu3=!;&u4=%pu4=!;&u5=%pu5=!;&cntr_url=%p~oref=!?" width="1" height="1" style="position: absolute; left: -150px;" />`,
    postback : `https://pixel.sitescout.com/up/68b1353efe8fffe0?`
}

export const paqueteAlambrico = {
    tag : () => {
        cntrUpTag.track('cntrData', '21ec1e5926d4057a');
    },
    floodlight : () =>{
        cntrUpTag.track('cntrData', '21ec1e5926d4057a');
    },
    image: `<img src="https://pixel.sitescout.com/up/21ec1e5926d4057a?cntr_revenue=%pcost=!;&cntr_transactionId=%pord=!;&u1=%pu1=!;&u2=%pu2=!;&u3=%pu3=!;&u4=%pu4=!;&u5=%pu5=!;&cntr_url=%p~oref=!?" width="1" height="1" style="position: absolute; left: -150px;" />`,
    postback : `https://pixel.sitescout.com/up/21ec1e5926d4057a?`
}

export const checkoutStepOneAlambrico = {
    tag : () => {
        cntrUpTag.track('cntrData', '881a0d9aec5182b7');
    },
    floodlight : () =>{
        cntrUpTag.track('cntrData', '881a0d9aec5182b7');
    },
    image: `<img src="https://pixel.sitescout.com/up/881a0d9aec5182b7?cntr_revenue=%pcost=!;&cntr_transactionId=%pord=!;&u1=%pu1=!;&u2=%pu2=!;&u3=%pu3=!;&u4=%pu4=!;&u5=%pu5=!;&cntr_url=%p~oref=!?" width="1" height="1" style="position: absolute; left: -150px;" />`,
    postback : `https://pixel.sitescout.com/up/881a0d9aec5182b7?`
}

export const checkoutStepTwoAlambrico = {
    tag : () => {
        cntrUpTag.track('cntrData', 'a11797d5e286c7ed');
    },
    floodlight : () =>{
        cntrUpTag.track('cntrData', 'a11797d5e286c7ed');
    },
    image: `<img src="https://pixel.sitescout.com/up/a11797d5e286c7ed?cntr_revenue=%pcost=!;&cntr_transactionId=%pord=!;&u1=%pu1=!;&u2=%pu2=!;&u3=%pu3=!;&u4=%pu4=!;&u5=%pu5=!;&cntr_url=%p~oref=!?" width="1" height="1" style="position: absolute; left: -150px;" />`,
    postback : `https://pixel.sitescout.com/up/a11797d5e286c7ed?`
}

/* END ALAMBRICO */

/* START INALAMBRICO */
export const landingInalambrico = {
    tag : () => {
        cntrUpTag.track('cntrData', 'c93b48fe0e8a76b8');
    },
    floodlight : () =>{
        cntrUpTag.track('cntrData', 'c93b48fe0e8a76b8');
    },
    image: `<img src="https://pixel.sitescout.com/up/c93b48fe0e8a76b8?cntr_revenue=%pcost=!;&cntr_transactionId=%pord=!;&u1=%pu1=!;&u2=%pu2=!;&u3=%pu3=!;&u4=%pu4=!;&u5=%pu5=!;&cntr_url=%p~oref=!?" width="1" height="1" style="position: absolute; left: -150px;" />`,
    postback : `https://pixel.sitescout.com/up/c93b48fe0e8a76b8?`
}


export const postpagoInalambrico = {
    tag : () => {
        cntrUpTag.track('cntrData', 'b56014de2ebfb1c3');
    },
    floodlight : () =>{
        cntrUpTag.track('cntrData', 'b56014de2ebfb1c3');
    },
    image: `<img src="https://pixel.sitescout.com/up/b56014de2ebfb1c3?cntr_revenue=%pcost=!;&cntr_transactionId=%pord=!;&u1=%pu1=!;&u2=%pu2=!;&u3=%pu3=!;&u4=%pu4=!;&u5=%pu5=!;&cntr_url=%p~oref=!?" width="1" height="1" style="position: absolute; left: -150px;" />`,
    postback : `https://pixel.sitescout.com/up/b56014de2ebfb1c3?`
}

export const prepagoInalambrico = {
    tag : () => {
        cntrUpTag.track('cntrData', 'f7431b0d02979fcc');
    },
    floodlight : () =>{
        cntrUpTag.track('cntrData', 'f7431b0d02979fcc');
    },
    image: `<img src="https://pixel.sitescout.com/up/f7431b0d02979fcc?cntr_revenue=%pcost=!;&cntr_transactionId=%pord=!;&u1=%pu1=!;&u2=%pu2=!;&u3=%pu3=!;&u4=%pu4=!;&u5=%pu5=!;&cntr_url=%p~oref=!?" width="1" height="1" style="position: absolute; left: -150px;" />`,
    postback : `https://pixel.sitescout.com/up/f7431b0d02979fcc?`
}


export const paqueteInalambrico = {
    tag : () => {
        cntrUpTag.track('cntrData', 'f17498379618f138');
    },
    floodlight : () =>{
        cntrUpTag.track('cntrData', 'f17498379618f138');
    },
    image: `<img src="https://pixel.sitescout.com/up/f17498379618f138?cntr_revenue=%pcost=!;&cntr_transactionId=%pord=!;&u1=%pu1=!;&u2=%pu2=!;&u3=%pu3=!;&u4=%pu4=!;&u5=%pu5=!;&cntr_url=%p~oref=!?" width="1" height="1" style="position: absolute; left: -150px;" />`,
    postback : `https://pixel.sitescout.com/up/f17498379618f138?`
}

export const coberturaInalambrico = {
    tag : () => {
        cntrUpTag.track('cntrData', 'a678615dd674d32e');
    },
    floodlight : () =>{
        cntrUpTag.track('cntrData', 'a678615dd674d32e');
    },
    image: `<img src="https://pixel.sitescout.com/up/a678615dd674d32e?cntr_revenue=%pcost=!;&cntr_transactionId=%pord=!;&u1=%pu1=!;&u2=%pu2=!;&u3=%pu3=!;&u4=%pu4=!;&u5=%pu5=!;&cntr_url=%p~oref=!?" width="1" height="1" style="position: absolute; left: -150px;" />`,
    postback : `https://pixel.sitescout.com/up/a678615dd674d32e?`
}


export const checkoutStepOneInalambrico = {
    tag : () => {
        cntrUpTag.track('cntrData', '00db4cd73b8276e6');
    },
    floodlight : () =>{
        cntrUpTag.track('cntrData', '00db4cd73b8276e6');
    },
    image: `<img src="https://pixel.sitescout.com/up/00db4cd73b8276e6?cntr_revenue=%pcost=!;&cntr_transactionId=%pord=!;&u1=%pu1=!;&u2=%pu2=!;&u3=%pu3=!;&u4=%pu4=!;&u5=%pu5=!;&cntr_url=%p~oref=!?" width="1" height="1" style="position: absolute; left: -150px;" />`,
    postback : `https://pixel.sitescout.com/up/00db4cd73b8276e6?`
}

export const checkoutStepTwoInalambrico = {
    tag : () => {
        cntrUpTag.track('cntrData', '81b2813aca95bbd9');
    },
    floodlight : () =>{
        cntrUpTag.track('cntrData', '81b2813aca95bbd9');
    },
    image: `<img src="https://pixel.sitescout.com/up/81b2813aca95bbd9?cntr_revenue=%pcost=!;&cntr_transactionId=%pord=!;&u1=%pu1=!;&u2=%pu2=!;&u3=%pu3=!;&u4=%pu4=!;&u5=%pu5=!;&cntr_url=%p~oref=!?" width="1" height="1" style="position: absolute; left: -150px;" />`,
    postback : `https://pixel.sitescout.com/up/81b2813aca95bbd9?`
}

export const checkoutStepThreeInalambrico = {
    tag : () => {
        cntrUpTag.track('cntrData', 'f34167f26228fea2');
    },
    floodlight : () =>{
        cntrUpTag.track('cntrData', 'f34167f26228fea2');
    },
    image: `<img src="https://pixel.sitescout.com/up/f34167f26228fea2?cntr_revenue=%pcost=!;&cntr_transactionId=%pord=!;&u1=%pu1=!;&u2=%pu2=!;&u3=%pu3=!;&u4=%pu4=!;&u5=%pu5=!;&cntr_url=%p~oref=!?" width="1" height="1" style="position: absolute; left: -150px;" />`,
    postback : `https://pixel.sitescout.com/up/f34167f26228fea2?`
}

export const checkoutStepFourInalambrico = {
    tag : () => {
        cntrUpTag.track('cntrData', 'd80b50afb442c6af');
    },
    floodlight : () =>{
        cntrUpTag.track('cntrData', 'd80b50afb442c6af');
    },
    image: `<img src="https://pixel.sitescout.com/up/d80b50afb442c6af?cntr_revenue=%pcost=!;&cntr_transactionId=%pord=!;&u1=%pu1=!;&u2=%pu2=!;&u3=%pu3=!;&u4=%pu4=!;&u5=%pu5=!;&cntr_url=%p~oref=!?" width="1" height="1" style="position: absolute; left: -150px;" />`,
    postback : `https://pixel.sitescout.com/up/d80b50afb442c6af?`
}

export const checkoutStepFiveInalambrico = {
    tag : () => {
        cntrUpTag.track('cntrData', '7ce4aae79b63c303');
    },
    floodlight : () =>{
        cntrUpTag.track('cntrData', '7ce4aae79b63c303');
    },
    image: `<img src="https://pixel.sitescout.com/up/7ce4aae79b63c303?cntr_revenue=%pcost=!;&cntr_transactionId=%pord=!;&u1=%pu1=!;&u2=%pu2=!;&u3=%pu3=!;&u4=%pu4=!;&u5=%pu5=!;&cntr_url=%p~oref=!?" width="1" height="1" style="position: absolute; left: -150px;" />`,
    postback : `https://pixel.sitescout.com/up/7ce4aae79b63c303?`
}

export const checkoutStepSixInalambrico = {
    tag : () => {
        cntrUpTag.track('cntrData', '4416667676e20517');
    },
    floodlight : () =>{
        cntrUpTag.track('cntrData', '4416667676e20517');
    },
    image: `<img src="https://pixel.sitescout.com/up/4416667676e20517?cntr_revenue=%pcost=!;&cntr_transactionId=%pord=!;&u1=%pu1=!;&u2=%pu2=!;&u3=%pu3=!;&u4=%pu4=!;&u5=%pu5=!;&cntr_url=%p~oref=!?" width="1" height="1" style="position: absolute; left: -150px;" />`,
    postback : `https://pixel.sitescout.com/up/4416667676e20517?`
}


export const effectivo = {
    tag : () => {
        cntrUpTag.track('cntrData', 'f7431b0d02979fcc');
    },
    floodlight : () =>{
        cntrUpTag.track('cntrData', 'f7431b0d02979fcc');
    },
    image: `<img src="https://pixel.sitescout.com/up/f7431b0d02979fcc?cntr_revenue=%pcost=!;&cntr_transactionId=%pord=!;&u1=%pu1=!;&u2=%pu2=!;&u3=%pu3=!;&u4=%pu4=!;&u5=%pu5=!;&cntr_url=%p~oref=!?" width="1" height="1" style="position: absolute; left: -150px;" />`,
    postback : `https://pixel.sitescout.com/up/f7431b0d02979fcc?`
}


export const thankyouPage = {
    tag : () => {
        cntrUpTag.track('cntrData', '2f6d019271e2cc35');
    },
    floodlight : () =>{
        cntrUpTag.track('cntrData', '2f6d019271e2cc35');
    },
    image: `<img src="https://pixel.sitescout.com/up/2f6d019271e2cc35?cntr_revenue=%pcost=!;&cntr_transactionId=%pord=!;&u1=%pu1=!;&u2=%pu2=!;&u3=%pu3=!;&u4=%pu4=!;&u5=%pu5=!;&cntr_url=%p~oref=!?" width="1" height="1" style="position: absolute; left: -150px;" />`,
    postback : `https://pixel.sitescout.com/up/2f6d019271e2cc35?`
}

/* END INALAMBRICO */