<template>
<div>
    <div class="hero_banner" data-aos="fade" v-if="loading">
        <HeroLoader > </HeroLoader> 
    </div>
    <div class="hero_banner" data-aos="fade" v-if="!loading">
        <carousel :items-to-show="1" v-if="data" :breakpoints="breakpoints"  :settings="settings">
            <slide v-for="slide in data.list" :key="slide" >
                <video  v-if="slide.video_descktop !='' && slide.video_descktop !=null" class="w-full rounded-2xl min-h-full flex justify-end flex-wrap relative hero-slide-container lg:rounded-none md:rounded-none xs:w-full xs:rounded-none sm:w-full sm:rounded-none" controls muted >
                    <source :src="slide.video_descktop" type="video/mp4">
                    <source :src="slide.video_descktop" type="video/ogg">
                    Your browser does not support the video tag.
                </video>
                <div class="w-full rounded-2xl min-h-full flex justify-start flex-wrap relative hero-slide-container lg:rounded-none md:rounded-none xs:w-full xs:rounded-none sm:w-full sm:rounded-none" :style="{  background:`url(${ getImagePerResoluition( slide.image_descktop, slide.image_mobile, currentMediaQuery) })`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'left center'}" v-else>
                    <div class="hero-banner-gradient xs:pb-14 sm:pb-14 bg-gradient-to-l p-5 
                            text-white float-left xl:flex 2xl:flex lg:flex lg:rounded-none md:flex md:justify-center lg:justify-center xl:justify-center 2xl:justify-center md:flex-col lg:flex-col xl:flex-col 2xl:flex-col xs:flex xs:flex-col xs:justify-end sm:flex sm:flex-col sm:justify-end
                            xs:rounded-none xs:bg-gradient-to-b xs:flex-1 xs:text-center sm:rounded-none sm:bg-gradient-to-b sm:flex-1 sm:text-center rounded-2xl md:rounded-none xs:pt-10 xs:w-full w-6/12 md:pr-0 lg:pl-8 lg:w-7/12 md:w-3/6 md:pl-10 xl:pl-12 2xl:pl-12
                            ">
                        <h2 class="text-4xl uppercase md:text-4xl md:bold lg:text-5xl lg:bold m-0 xl:text-5xl xl:bold 2xl:text-5xl 2xl:bold md:text-left lg:text-left xl:text-left 2xl:text-left text-white">  {{ slide.title }}</h2>
                        <p class="mt-5 md:text-left sm:text-left lg:text-left xl:text-left 2xl:text-left"> {{ slide.text_secondary }} </p>
                        <h3  class="mt-5 text-2xl mb-3 md:text-left sm:text-center lg:text-left xl:text-left 2xl:text-left" > <span class="mt-5 text-5xl font-semibold "> {{ slide.text_price }} </span></h3>
                        <div class="flex justify-center mt-4 md:justify-start lg:justify-start xl:justify-start 2xl:justify-start" v-if="slide.text_call_to_action">
                            <a class="btn-green m-0 px-5  w-56"  target="_blank" rel="noreferrer" @click.prevent="setPageNumber(slide.type_redirect)" v-if="slide.type_redirect.split('|')[0] == 'I'" >  {{ slide.text_call_to_action }} </a>
                            <a class="btn-green m-0 px-5  w-56" :href="slide.type_redirect.split('|')[2]" target="_blank" rel="noreferrer"  v-if="slide.type_redirect.split('|')[0] == 'E'">  {{ slide.text_call_to_action }} </a>

                        </div>
                         <div class="xs:pt-2 sm:pt-2 xs:w-full sm:w-full md:w-full lg:w-7/12 xl:w-7/12 2xl:w-7/12 md:absolute lg:absolute xl:absolute 2xl:absolute text-left text-white legal_copy" v-html="slide.text_legals"></div>
                    </div>
                    <div class="hidden" v-if="slide.imagen_logo">
                        <img :src="slide.imagen_logo" :alt="slide.titulo_principal" >
                    </div>
                </div>
            </slide>
            <template #addons>
                <div class="pagination_container">
                    <pagination class="black-bottom-pagination-container xs:left-0 xs:right-0 xs:bottom-3.5 sm:left-0 sm:right-0 sm:bottom-3.5 md:left-9" aria-label="hero-button" />
                </div>
            </template>
        </carousel>
    </div>
</div>
</template>
<style>
.legal_copy a{
    text-decoration: underline;
}
</style>
<style scoped> 
    .hero-banner-gradient  {
        background:linear-gradient(90deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 55.59%);
    }
    .legal_copy {
        font-size: 9px;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
        bottom:2rem;
        left:18rem;
        line-height: 10px;
    }
    .hero-slide-container {
        height: 80vh;
    }
    .hero-banner-slide{
        height: 730px;
        min-width: 80vh;
    }
    .hero-slide-container {
        height:639px !important
    }
    .hero-banner-slide-content{
        height: 100%;
    }
    .hero_banner{
        @apply m-auto my-5 xs:my-0 sm:my-0 xs:mx-0 md:m-0 lg:mt-0 md:mt-0 sm:mt-0 rounded-2xl;
        max-width: 1368px;
    }
    .hero_banner .carousel__viewport {
        @apply xl:rounded-2xl 2xl:rounded-2xl;
        box-shadow:0px 4px 4px rgba(0,0,0,0.25); 
    } 
    @media only screen and (min-width: 1281px) and (max-width: 1366px) {
        .hero_banner {
            padding: 0;
        }
    }
    @media only screen and (min-width: 1025px) and (max-width: 1440px) {
        .hero-slide-container {
            height:639px
        }
    }
    @media only screen and (max-width:767px) {
        .hero-banner-gradient  {
            background:linear-gradient(0deg, rgba(0,0,0,0.7), rgba(0,0,0,0))
        }
         .hero_banner .carousel__viewport {
            box-shadow: none;
        }
        .carousel__viewport {
            border-radius: 0px !important;
        }
    }    
    @media  only screen and (min-width:321px) and (max-width:767px){
        .hero-slide-container{
            height: 90vh;
        }
        .legal_copy {
            font-size:8px;
        }
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        .legal_copy {
            left:10rem;
            width:60%;
        }
    }
</style>
<style>
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        .carousel__viewport {
            border-radius:0px !important
        }
    }
    @media only screen and (max-width:767px) {
        .hero_banner .carousel__viewport {
            border-radius: 0px !important;
        }
        .hero_banner .black-bottom-pagination-container {
            left:0rem !important
        }
    }   
    .hero_banner .black-bottom-pagination-container {
        left:3rem
    }
</style>
<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';
import { mapState, useStore } from 'vuex'
import { inject, onMounted } from '@vue/runtime-core';
import HeroLoader from '@/components/loaders/HeroLoader.vue'
import {carouselClick } from '@/events/click'

export default {
    name: 'HeroBanner',
    props:['data'],
    computed:{
        ...mapState({
            loading: state => state.Home.loading
        })
    },
    components: {
        Carousel,
        Slide,
        Pagination,
        HeroLoader
    },
    setup( ) {
        const store = useStore()
        const currentMediaQuery = inject('mq')
        const settings =  {
           transition:600
        }
        const breakpoints = {
            1024: {
                mouseDrag: false
            },
        }
        const setPageNumber = ( type_redirect ) =>  {
            const type = type_redirect.split('|')[0]
			const pageNumber = type_redirect.split('|')[1]
			const link = type_redirect.split('|')[2]

			if( type == 'I'){
				if( pageNumber ){
					store.commit( 'SET_PAGE_NUMBER', pageNumber )
                    window.scrollTo(0, 0);
				}
			} else {
				window.location.href = link;
			}
        }
        const getImagePerResoluition = ( desktopImage, mobileImage, mediaQuery ) => {
           return (mediaQuery.current == 'xs' ?  mobileImage ?  mobileImage.replace(/ /g,'%20') : ""  : desktopImage ? desktopImage.replace(/ /g,'%20') : "" )
        }

        onMounted(() => {
            carouselClick()
        })

        return { getImagePerResoluition, setPageNumber,  settings, breakpoints, currentMediaQuery}
    },
    created(){
        setTimeout( () => {
            document.querySelectorAll('button.carousel__pagination-button').forEach( element => {
                element.setAttribute('aria-label', 'paginates' )
                element.setAttribute('name', 'btn-paginate')
            })
        },1000)
    }
}
</script>
