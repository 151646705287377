<template>
    <div class="bg-gray-250 py-5 px-10 rounded-xl details_container">
        <h3 class="text-red uppercase text-2xl"><Skeletor width="50%"> </Skeletor></h3>
        <div class="separator_form"></div>
        <div class="w-full">
            <p> <Skeletor width="50%"> </Skeletor> </p>
            <div class="flex rounded-3xl justify-between  bg-gray-150  px-5 py-4 my-2 items-center max-h-13">
                <Skeletor width="100px"> </Skeletor>
                <Skeletor width="100px" class="ml-2"> </Skeletor>
            </div>
        </div>
        <div class="separator_form" style="margin-top:20px;"></div>
        <div class="w-full">
            <div class="flex  justify-between my-3">
                <Skeletor width="150px"> </Skeletor>
                <Skeletor width="50px"> </Skeletor> 
            </div>
                <div class="flex  justify-between my-3">
                <Skeletor width="150px"> </Skeletor>
                <Skeletor width="50px"> </Skeletor>
            </div>
            <div class="flex justify-between my-3">
                <Skeletor width="150px"> </Skeletor> 
                <Skeletor width="100px"> </Skeletor> 
            </div>
        </div>
    </div>
</template>
<script>
import { Skeletor } from 'vue-skeletor';

export default {
    components: { Skeletor },
}
</script>