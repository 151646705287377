<template>
  <div class="home" :style="mobileBodyHeight">
    <Header />
    <div ref="bodyRef_" class="mx-auto my-5 xs:pb-24 sm:pb-24 md:px-6 xs:my-0 xs:mx-0 md:mx-0 md:container 2xl:container xl:container lg:container xs:w-full sm:w-full xl:p-10 relative xs:flex sm:flex xs:flex-col sm:flex-col">
        <CheckoutNavigation :step="2"  class="xs:order-1 sm:order-1"/>
        <div class="flex mt-5 justify-between xs:flex-col sm:flex-col xs:order-2 sm:order-2">
          <div class="w-6/12 xs:w-full sm:w-full">
          <Pago  />
          </div>
          <div class="w-5/12 xs:hidden sm:hidden" >
            <Details />
          </div>
        </div>
        <div class="w-full md:hidden lg:hidden absolute top-0 details-mobile xs:order-3 sm:order-3" v-show="isShowDetails">
            <Details />
          </div>
        <div class="xs:visible sm:visible invisible bottom-nav flex items-center bg-white w-full p-5 justify-center step-mobile" v-if="!isShowDetails">
            <button class="btn-black mr-4" @click.prevent=" onShowDetails(); " > Ver detalles </button>
        </div>
        <div class="xs:visible sm:visible invisible bottom-nav flex items-center bg-white w-full p-5 justify-center step-mobile" v-if="isShowDetails">
            <button class="btn-black mr-4" @click.prevent=" onShowDetails()" > Regresar </button>
        </div>
    </div>
    <Footer /> 
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import CheckoutNavigation from '@/components/checkout/Navigation.vue'
import Pago from '@/components/checkout/Pago.vue'
import Details from '@/components/checkout/Details.vue'
import { mapState, mapGetters, useStore } from 'vuex';
import Footer from '@/components/Footer.vue'
import { inject, onUnmounted, onMounted } from '@vue/runtime-core'
import { ref, isRef } from '@vue/reactivity'

export default {
  name: 'Home',
  components: {
    Header,
    CheckoutNavigation,
    Pago,
    Footer,
    Details
  },
  computed: {
    ...mapGetters({
      mobileBodyHeight: 'GET_MOBILE_BODY_HEIGHT'
    }),
    ...mapState(['Subscription'] )
  },
  setup(){
    const PagoFlow = ref( null )
    const bodyRef_ = ref(0)
    const isShowDetails = ref(false)
    const store = useStore()
    const currentMediaQuery_ = inject('mq')
    onMounted(() => { 
      const currentMediaQuery = currentMediaQuery_.current
      const bodyRef = bodyRef_.value
      const bodyIsRef =  isRef(bodyRef_)
      store.commit('SET_MOBILE_BODY_HEIGHT', {currentMediaQuery, bodyRef,  bodyIsRef})           
    })
    onUnmounted(() => {
      store.commit('STOP_INTERVAL_MOBILE', true)
    })
    const onShowDetails = () =>{
      isShowDetails.value = !isShowDetails.value
    }
    const onNext = () =>  {

    }
    return { isShowDetails, PagoFlow,  onShowDetails, onNext, currentMediaQuery_, bodyRef_ }

  }
}
</script>
<style scoped>
.bottom-nav{
  position: fixed;
  bottom: 0;
 z-index: 11
}
.details-mobile{
  height: 100vh;
  z-index: 10;
}
</style>