<template>
<div v-if="data" id="myDIV">
    <iframe :src="data.value" frameborder="0" width="100%"  scrolling="no" :height="getHeight()"></iframe>
</div>
</template>
<script>
import { inject } from '@vue/runtime-core'
export default {
    props: [ 'data' ],
    setup(){
        const currentMediaQuery = inject('mq')
        const largeMediaQueries = [ 'md','lg','xl','xxl']
        const getHeight = () => {
            // return ( largeMediaQueries.includes(currentMediaQuery.value) ? 2000 : 3624 )
            return ( largeMediaQueries.includes(currentMediaQuery.value) ? 2000 : 5060 )
        }
        
        return{ currentMediaQuery, getHeight}
    }
}
</script>

<style>
#myDIV {
    height:5000px;
    background-color:#FFFFFF;
  overflow-y: scroll;
  }

</style>