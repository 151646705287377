
<template>
<div class="row packages-container xs:px-6 sm:px-6 md:px-6">
    <PackageBannerLoader v-if="loading" />
        <carousel :itemsToScroll="1" :breakpoints="breakpoints" class="carousel_dish "  :class="{ 'is-center' : data.length < 3 }" v-if="data" >
            <slide v-for="slide in data.list" :key="slide" :wrap-around="true">
                <div class="flex flex-col w-full lg:mx-2 xl:mx-2 2xl:mx-2 mb-10 xs:mb-8 sm:mb-8 md:w-3/6 mt-12 pt-10 rounded-2xl dish relative items-stretch justify-between " :class="{ 'bg-gray-100' : slide.is_featured != 'SI' , 'bg-purple-100' : slide.is_featured == 'SI'}">                
                    <div>
                        <div v-if="slide.is_featured == 'SI'" class="bg-black text-white text-lg font-semibold absolute  w-full p-2 rounded-tr-2xl rounded-tl-2xl -top-7"> {{slide.text_features}} </div>  
                        <div v-else class="bg-green text-white absolute  -top-0 w-full p-2 rounded-tr-2xl rounded-tl-2xl"> </div>     
                        <div>
                            <h2 class="text-4xl font-semibold xs:mb-3 sm:mb-3 px-6 pb-2 mb-0"
                                :class="{ 'text-black' : slide.is_featured != 'SI', 
                                        'text-white' : slide.is_featured == 'SI' }"> {{ slide.serialize.nombre_paq  }}</h2>
                            <span class="font-light text-18"
                                :class="{ 'text-black' : slide.is_featured != 'SI', 
                                        'text-white' : slide.is_featured == 'SI' }"
                                v-if="slide.serialize.descrip_paq" v-html="slide.serialize.descrip_paq"></span>   
                           <!-- <span class="font-light xs:hidden sm:hidden md:visible lg:visible xl:visible 2xl:visible"
                                :class="{ 'text-black' : !slide.serialize.check_destacado, 
                                        'text-white' : slide.serialize.check_destacado,}" v-else v-html="`<div style='height:24px'></div>`"></span>   -->
                            <div :class="{ 'text-black' : slide.is_featured != 'SI', 
                                        'text-white' : slide.is_featured == 'SI' }"> {{slide.serialize.descript_paq}}</div>
                            <h3 class="text-6xl font-semibold xs:text-5xl xs:mb-2 pt-2 sm:mb-2" 
                                :class="{ 'text-green' : slide.is_featured != 'SI' ,
                                        'text-white' : slide.is_featured == 'SI' }" >
                                        
                             ${{ slide.package.price }} <span class="text-2xl">/mes</span></h3>        
                        </div>
                        <div class="flex xs:flex-col mt-3">
                            <div class="canales_container xs:w-11/12 bg-white sm:w-11/12 flex py-5 justify-center xl:w-11/12 2xl:w-11/12 items-center xs:justify-end xs:pr-14 font-semibold rounded-r-full px-7 2xl:px-9 lg:p-1"
                                :class="{ 'text-18' : !slide.serialize.check_destacado, 
                                        'text-24' : slide.serialize.check_destacado }">
                                <div v-for="(detail, index) in slide.serialize.detalles_paquete" :key="index" v-show="detail.text != '' || detail.image != ''">
                                    <img :src="detail.image" :alt="detail.image" v-if="detail.image">
                                        <p v-else  >{{detail.text}}</p>

                                </div>
                            </div>
                        </div>
                        <div class="flex mt-2 px-3 text-center">
                            <a href="#" class=" text-center underline semibold text-sm w-full font-bold"
                            :class="{ 'text-purple-100 ' :  slide.is_featured != 'SI' ,  'text-white' :  slide.is_featured == 'SI'  }" @click.prevent="isShow = true; isChannels = true ; disableScroll(false); productData = slide "> ¿Cómo funciona? </a>
                        </div>
                    </div>
                    <div style="height:inherit">
                        <div>
                            <p  class="font-semibold text-xl xs:hidden mt-4" 
                                :class="{ 'text-black ' :  slide.is_featured != 'SI' , 
                                        'text-white' :  slide.is_featured == 'SI'  }"> Beneficios  </p>
                            <ul class="flex flex-wrap items-center benefits-items">
                                    <li class="items-center flex rounded-lg dish-item-includes " v-for="(benefit, index ) in slide.serialize.benefis_pack" :key="index" :class="{'w-6/12' : slide.serialize.benefis_pack.length > 1, 'w-full' : slide.serialize.benefis_pack.length == 1 }"> 
                                        <a href="#" class="h-full w-full rounded-lg xs:text-xs text-sm p-3 leading-4 bg-white flex justify-center items-center m-3" v-if="benefit.image"> 
                                            <img :src="benefit.image" alt="benefit image">
                                        </a>
                                        <a href="#" class="h-full w-full rounded-lg xs:text-xs text-sm p-3 leading-4 bg-white flex justify-center items-center m-3" v-else>
                                            {{ benefit.text }}
                                        </a>
                                    </li>    
                                <li v-for="item in slide.serialize.repeat_detalles" :key="item"
                                    class="w-5/12 items-center bg-white  mx-2 flex rounded-lg dish-item-includes " v-show="false">
                                    <img :src="item.image" :alt="item.text" v-if="!isArray(item.image)" class="mx-auto">
                                    <img :src="item.image" :alt="item.text" v-if="isArray(item.image)" class="mx-auto">
                                    <a href="#" v-if="item.note" class="mx-auto"> {{ item.note }} </a>
                                </li>
                            </ul>
                        </div> 
                    </div>
                    <div>
                        <div class="bg-black p-5 my-5 mb-2" v-if="slide.serialize.etiqueta_franj">
                            <p class="text-white" v-html="slide.serialize.etiqueta_franj"> </p>
                        </div>
                        <div class="bottom-5 w-full lg:bottom-2 xs:flex-col xs:justify-center xs:flex xs:w-max xs:m-auto pb-6 mt-10 mb-4">
                            <a href="#" class="m-1 xs:p-3 font-normal" :class="{'btn-green' : !slide.serialize.check_destacado,  'btn-light' : slide.serialize.check_destacado, } " @click="onSubmit(slide);"> {{ slide.serialize.texto_contrata }} </a> 
                        </div>
                    </div> 
                   <div class="bg-purple-50 p-5 text-center flex flex-col rounded-br-2xl rounded-bl-2xl" v-if="slide.serialize.texto_linea_1">
                        <h3 class="text-xl font-bold">{{slide.serialize.texto_linea_1}}</h3>
                        <h3 class="text-purple-100 text-3xl font-bold mb-0">{{slide.serialize.texto_linea_2}}</h3>
                        <h3 class="text-purple-100 font-bold text-18">{{slide.serialize.texto_linea_3}}</h3>
                    </div>
                </div>
                <div v-html="tagImage" class="tagImagePixel"> </div>
                <div v-html="tagImageCobertura" class="tagImagePixelCovertura" />
            </slide>
            <template #addons="{ slidesCount }">
            <navigation class="xs:hidden sm:hidden" v-if="slidesCount > 3"/>
            <pagination class="lg:hidden xl:hidden 2xl:hidden"/>
            </template>
        </carousel>
        
        <div class="legal_copy2 xs:p-0 sm:p-0 xs:mt-2 sm:mt-2" v-html="textLegal"> </div>
        <ModalPersonalize v-if="isShow" :isChannels="isChannels" @onClose="onClose" @setChannels="setChannels" :productData="productData" @onSubmit="onSubmit" />
    <transition name="fade"> 
        <ModalMap v-if="isShowMap" :onShow="isShowMap" @onClose="onCloseMap"  @onSubmit="onSubmit" :productData="productData" :ref="data.title"/>
    </transition>
</div>
</template>
<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import { ref } from '@vue/reactivity'
import { useRouter } from 'vue-router';
import { mapState, useStore } from 'vuex'
import { inject } from '@vue/runtime-core'
import ModalPersonalize from '@/components/modals/ModalPersonalize'
import PackageBannerLoader from "@/components/loaders/Packages.vue"
import ModalMap from '@/components/modals/ModalMap.vue'
import { landingAlambrico, landingInalambrico, paqueteAlambrico, coberturaInalambrico, paqueteInalambrico } from '@/pixel/pixel.js'
export default {
    name: 'PackageCarousel',
    props: ['data'],
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
        ModalPersonalize,
        PackageBannerLoader,
        ModalMap
    },
    computed:{
        ...mapState({
            loading : state =>  state.Home.loading
        })
    },
    setup() {
        const textLegal = ref("")
        const isShowMap = ref(false)
        const isShow = ref(false)
        const store = useStore()
        const productData = ref({})
        const isChannels = ref( false )
        const tagImageCobertura = ref('')
        const tagImage  = ref('')
        const settings = {
            itemsToShow: 1,
            transition: 600
        }
        const breakpoints =  {
            // 700px and up
            700: {
                itemsToShow: 1,
                snapAlign: 'start',
            },
            // 1024 and up
            1024: {
                itemsToShow: 3,
                mouseDrag: false
            },
        }
        const currentMediaQuery_ = inject('mq')
        const currentMediaQuery = currentMediaQuery_.current
        const router =  useRouter()

        const waitRedirect = ( product,  isInalambrico , pathName ) => {
            triggerPixelLanding(product, isInalambrico)
            setTimeout( () => {
                router.push({ name: pathName })
            }, 500 )
        }
        
        const onSubmit =( product ) =>{
            const scrollPos = document.documentElement.scrollTop
            store.commit('SET_SCROLL_POSITION', scrollPos)
            // al = satilatal  
            // in radio_inalam

            //radio_inalam 1 3 satilatl
            let inAlambrico = [ 1, 3]

            console.log( "producto",product.package.name)
            let text = product.package.name;
            let result = text.indexOf("SATELITAL");
            console.log("result",result)

            if( product.serialize.check_revisar_al == true || product.serialize.check_revisar_in == true){

// condicion agregada
                if(result>=0){
                    {
                                console.log("ALAM")
                                //    const isAlambrico = inAlambrico.includes(product.serialize.radio_inalam)
                                const isAlambrico = false
                                console.log("alambricosss",isAlambrico)

                                store.dispatch('setProduct', { ...product.package, ...product.serialize  })
                                //    waitRedirect( product , isAlambrico ,isAlambrico ? 'Register' :  'RegisterAlambrico' )
                                waitRedirect( product , isAlambrico ,isAlambrico ? 'Register' :  'RegisterAlambrico' )
                                
                            
                            }      
                }

               
                
                 if ( product.validate){
                     console.log("regis",)
    
                   const isAlambrico = inAlambrico.includes(product.serialize.radio_inalam)
                   store.dispatch('setProduct', { ...product.package, ...product.serialize  })
                   waitRedirect( product , isAlambrico ,isAlambrico ? 'Register' :  'RegisterAlambrico' )

                } else {
                    productData.value = product
                    isShowMap.value = true

                    productData.value = product
                    isShowMap.value = true

                    coberturaInalambrico.tag()
                    coberturaInalambrico.floodlight()

                    const image =  coberturaInalambrico.image +`<img src="${coberturaInalambrico.postback}?erp_id=${product.package.erp_id}"/>`
                    tagImageCobertura.value = image

                }

            } else {
                console.log("ALAM")
                //    const isAlambrico = inAlambrico.includes(product.serialize.radio_inalam)
                   const isAlambrico = false
                console.log("alambricosss",isAlambrico)

                   store.dispatch('setProduct', { ...product.package, ...product.serialize  })
                //    waitRedirect( product , isAlambrico ,isAlambrico ? 'Register' :  'RegisterAlambrico' )
                   waitRedirect( product , isAlambrico ,isAlambrico ? 'Register' :  'RegisterAlambrico' )
                 
            
            }      
        }
        const setTextLegal= ( text ) => {
            textLegal.value = text 
        }
        const onClose = () => {
            isShow.value = false    
            store.commit('SET_LOGOS', [])
            store.commit('SET_SCROLLABLE',true)
        }
        const setChannels = ( data ) => {
            isChannels.value = data
        }
        const isArray = ( data ) => {
            return ( Array.isArray(data) ? true : false )
        }
        const onCloseMap= ( ) => {
            isShowMap.value = false
            store.commit('SET_SCROLLABLE', true)
        }
        const disableScroll = (boolean) => {  
            store.commit('SET_SCROLLABLE', {boolean, currentMediaQuery})
            const scrollPos = document.documentElement.scrollTop
            store.commit('SET_SCROLL_POSITION', scrollPos)
        }
        const isItemEmpty = ( obj ) => {

            let temp = ""
            Object.keys(obj).forEach( (el) => {
                temp =   obj[el]
            });
            if( temp ){
               temp =  temp[0].trim()
            }
            return temp == "" ? true : false
        }

        const triggerPixelLanding = ( product, isInalambrico ) =>{

            let image

            console.log( product.serialize.check_revisar_al, product)

            if( !isInalambrico ){
                landingAlambrico.tag()
                landingAlambrico.floodlight()
                landingAlambrico.image
                landingAlambrico.postback
               
                paqueteAlambrico.tag()
                paqueteAlambrico.floodlight()

                image =  landingAlambrico.image
                        + paqueteAlambrico.image
                        +`<img src="${landingAlambrico.postback}erp_id=${product.package.erp_id}"/>`
                        +`<img src="${paqueteAlambrico.postback}erp_id=${product.package.erp_id}"/>`
            } else {
                landingInalambrico.tag()
                landingInalambrico.floodlight()
                
                paqueteInalambrico.tag()
                paqueteInalambrico.floodlight()
                image =  landingInalambrico.image 
                        + paqueteInalambrico.image
                        +`<img src="${landingInalambrico.postback}erp_id=${product.package.erp_id}"/>`
                        +`<img src="${paqueteInalambrico.postback}erp_id=${product.package.erp_id}"/>`
            } 
            tagImage.value = image

        }

        
        
        return {
            breakpoints,
            tagImage,
            tagImageCobertura,
            settings,
            isShowMap,
            isShow, 
            isChannels,
            currentMediaQuery,
            productData,
            textLegal,
            isItemEmpty,
            isArray,
            setChannels,
            onClose,
            disableScroll,
            onSubmit,
            onCloseMap,
            setTextLegal,
            triggerPixelLanding
            }
    }
}
</script>
<style>
    @media only screen and (min-width:992px) {
        .dish_packages {
            max-width: 1187px !important;
        }
    }
    .carousel_dish li button{
        background-color: #c4c4c4 !important;
        width: 23px;
        height: 16px;
        border-radius: 20px;
    }
    .carousel_dish li button.carousel__pagination-button--active {
        background-color:#00872D !important;
        width:80px
    }
    .carousel_dish.is-center .carousel__track{
         @apply justify-center;
    }
    .carousel_dish .carousel__track {
        @apply  xs:justify-start sm:justify-start md:justify-start;
    }
    .carousel_dish .carousel__prev, .carousel_dish .carousel__next {
        @apply bg-gray-350 text-white;
        width:70px;
        height:70px;
        border-radius: 50%;
    }
    .carousel_dish .carousel__prev:hover, .carousel_dish .carousel__next:hover {
        background:#000 !important
    }
    @media only screen and (min-width: 1440px)  {
        .carousel_dish .carousel__prev {
        transform: translateX(-90px);
        -o-transform: translateX(-90px);
        -ms-transform: translateX(-90px);
        -webkit-transform: translateX(-90px);
        -ms-transform: translateX(-90px);
        }
        .carousel_dish .carousel__next {
            transform: translateX(90px);
            -o-transform: translateX(90px);
            -ms-transform: translateX(90px);
            -webkit-transform: translateX(90px);
            -ms-transform: translateX(90px);
        }
    }
    .carousel_dish .carousel__icon {
        transform: scale(2.5);
    }
    
</style>
<style scoped>
    .legal_copy2 {
        font-size:11px; 
        padding:0px 10px
    }
    .dish-item-includes{
        height: 60px;
        margin-top: 10px;

    }
    .packages-container {
        max-width:1200px;
        margin: 0 auto;
    }
    .dish-item-includes img {
        height: 100% !important;
    }
    .dish {
        height: 92%;
        box-shadow:0px 3px 11px rgba(0, 0, 0, 0.15)
    }
    .canales_container {
        min-height: 84px;
        max-width: 100%;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        @apply mb-3
    }

    .canales_container div{
        @apply relative mx-3 font-bold px-2 text-center justify-center flex items-center
    }

    .canales_container div:nth-child(even)::after{
        content: "+";
        @apply absolute -left-4 top-1/3

    }

    .canales_container div:nth-child(even)::before{
        content: "+";
        @apply absolute -right-4 top-1/3

    }
    
    @media only screen and (min-width:992px) {
        .carousel_ver2 .carousel__pagination{
            display: none;
        }
        
    }
    @media only screen and (max-width:991px) {
        .canales_container {
            max-width: unset;
        }
    }
    @media only screen and (max-width:480px) {
        .promotion_banner_card {
            min-height: 65vh;
        }
        .carousel__pagination {
            margin-top:0px !important;
        }
    }    
</style> 