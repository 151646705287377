import axios from 'axios'
import {endpoints} from '@/api/endpoints.js';
import { baseURL } from '../../api/environment';

const URL =  baseURL
export const setProduct = async ( {commit  }, data) => { 
    commit( 'SET_PACKAGE', data )
}

export const callPackagesData = async ( {commit, rootState  } ) => {
    try{
        const endpoint = endpoints.home.getPackageData.endpoint
        commit( 'LOADING_PACKAGES', true )
        const token    = await rootState.Token.token.dataObj
        const {data}   = await axios.request({
            url:`${endpoint}`,
            method:'get',
            baseURL : URL,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
                },
        })
        commit( 'SET_PACKAGES_DATA' , data)
    } catch( err ){
        commit( 'LOADING_PACKAGES', false )
        commit( 'ERROR_PACKAGES', err.message )
    }
}

export const callPackageInformation= async ( {commit, rootState  }, code ) => {
    try{
        const local = localStorage.getItem('SET_PACKAGES_INFORMATION');
        if( !local ){
            const endpoint = endpoints.packages.getPackageInformation.endpoint+'/'+code
            commit( 'LOADING_PACKAGES', true )
            const token    = await rootState.Token.token.dataObj
            const {data}   = await axios.request({
                url:`${endpoint}`,
                method:'get',
                baseURL : URL,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': token
                    },
            })
            commit( 'SET_PACKAGES_INFORMATION' , data.dataObj)
        }
    } catch( err ){
        commit( 'LOADING_PACKAGES', false )
        commit( 'ERROR_PACKAGES', err.message )
    } finally {
        commit( 'LOADING_PACKAGES', false )

    }
}


export const getAddressFromLatLong = async ( {commit}, params ) => {
    try{
        commit( 'LOADING_PACKAGES', true )        
        /* eslint-disable-next-line */
        const data   = await axios.request({
            url:`https://maps.googleapis.com/maps/api/geocode/json?latlng=${params.lat},${params.lng}&sensor=true&key=AIzaSyBftiC3hfNeseWaeLlVU1ftoKAfSWUFCkQ`,
            method:'get',
            baseURL : URL,
            responseType: 'json',            
        })
        return data
    } catch( err ){
        commit( 'LOADING_PACKAGES', false )
        commit( 'ERROR_PACKAGES', err.message )
        return false 
    } finally {
        commit( 'LOADING_PACKAGES', false )
    }
}


export const getOnCoverage = async ( {commit, rootState}, params ) => {
    try{


        const endpoint = params.isAlambrico ? endpoints.packages.getOnCoverage.endpoint : endpoints.packages.getOnCoverageInalambrico.endpoint 
        commit( 'LOADING_PACKAGES', true )
        const token  =  await rootState.Token.token.dataObj
        
        /* eslint-disable-next-line */
        const {data}   = await axios.request({
            url:`${endpoint}`,
            method:'post',
            baseURL : URL,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
                },
            data:{
                latitud : String(params.lat),
                longitud : String(params.lng),
                plan : params.plan}
        })

        if( data)

        if( Object.keys( data.dataObj ).length != 0){
            if( data.dataObj.codigo_respuesta == 222 ){
                commit( 'ERROR_PACKAGES', data.msg  )
                commit( 'LOADING_PACKAGES', false )
                return  { hasCoverage : false } 
            } else {
                if( data.dataObj.altan ){
                    if( data.dataObj.altan.hasCoverage ){
                        commit( 'SET_ON_COVERAGE' , data.dataObj )
                        return  { hasCoverage : true, data : data.dataObj }
                    }  else {
                         return  { hasCoverage : false, data : data.dataObj } 
                    }
                }
                if( data.dataObj.hispasat ){
                    if( data.dataObj.hispasat.hasCoverage ){
                        commit( 'SET_ON_COVERAGE' , data.dataObj )
                        return  { hasCoverage : true, data : data.dataObj }
                    }  else {
                         return  { hasCoverage : false, data : data.dataObj } 
                    }
                }
            }
        } else {
            commit( 'LOADING_PACKAGES', false )
            commit( 'ERROR_PACKAGES', data.msg  )
            return  { hasCoverage : false } 
        }
    } catch( err ){
        commit( 'LOADING_PACKAGES', false )
        commit( 'ERROR_PACKAGES', err.message )
        return false 
    } finally {
        commit( 'LOADING_PACKAGES', false )
    }
}
export const getLogos = async ( {commit, rootState}, product ) => {

    try{
        const endpoint = endpoints.packages.getLogos.endpoint
        commit( 'LOADING_PACKAGES', true )
        const token    = await rootState.Token.token.dataObj
       
        const {data}   = await axios.request({
            url:`${endpoint}`,
            method:'post',
            baseURL : URL,
            headers: {
                'Authorization': token
                },
            responseType: 'json',
            data:{
                clave : product
            }
        })
        commit( 'SET_LOGOS' , data.dataObj )
    } catch( err ){
        commit( 'LOADING_PACKAGES', false )
        commit( 'ERROR_PACKAGES', err.message )
    } finally {
        commit( 'LOADING_PACKAGES', false )
    }
}