export const SET_FRANKY = ( state, payload ) => {
    state.franky = payload 
}

export const LOADING_FRANKY  = ( state, boolean ) => {
    state.loading =  boolean
}

export const ERROR_FRANKY  = ( state, message ) => {
    state.error =  message
}