export const carouselClick = () =>{
    document.querySelectorAll('.carousel__pagination-button').forEach(el =>{
        el.addEventListener('click', () =>{
                document.querySelectorAll('video').forEach( element => {
                    element.pause();
                })
            })
        })

    document.querySelectorAll('video').forEach(el => {
        el.addEventListener( 'click',  () => {
            const elName = el.attributes.name
            document.querySelectorAll('video').forEach( video => {
                if( video.attributes.name  != elName){
                    video.pause()
                }
            })
        })
    })
}
