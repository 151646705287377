<template>
    <div class="text_intro">
        <div class="promotion_banner_card h-60 justify-between xs:flex xs:h-auto xs:text-center xs:bg-left xs:p-6 xs:min-h-0 p-7 xs:flex-col sm:flex sm:h-auto sm:text-center sm:bg-left sm:p-6 sm:min-h-0 sm:flex-col w-full rounded-2xl grid grid-cols-2  bg-gray-150 items-center">
            <img :src="data.image_descktop" alt="">
            <p v-html="data.text_description" class="sm:mt-7 copy"></p>            
        </div>
    </div>
</template>
<script>
export default {
    name: 'TextIntro',
    props:['data'],
}
</script>
  
<style scoped>
    .text_intro{
        @apply xs:p-6 sm:p-6 xs:mt-4 sm:mt-4 md:mt-4 mt-10 md:p-5 xs:pb-0 sm:pb-0 md:pb-0 mb-16 my-5 xs:mb-6 sm:mb-6 xs:my-0 xs:mx-0 md:mx-0 m-auto md:container lg:container xl:container ;
        max-width: 1200px;
    }
    .column:nth-child(even){
        border-left: solid 1px rgba(0, 0, 0, 0.4);
        border-right: solid 1px rgba(0, 0, 0, 0.4);
    } 
</style>
<style>
    .text_intro .copy p {
        @apply text-2xl w-10/12 font-extralight;
    }
    @media screen and (max-width:767px){
       .text_intro .copy p {
           @apply w-full text-left text-18 leading-6 mt-5
       } 
    }
</style>
