import config from "../../vue.config"
const current_url = location.href
console.log(current_url);
let env = ( current_url.indexOf('1p5z15263d.execute') > -1 ? 'dev'
        : current_url.indexOf('r2uqrpb9kg.execute') > -1 ? 'qa'
        : current_url.indexOf('llkaykxahe.execute') > -1 ? 'production' 
        : current_url.indexOf('test-eco-on.s3-website-us-east-1.amazonaws.com') > -1 ? 'production' 
        : current_url.indexOf('localhost') > -1 ? 'qa' 
        : current_url.indexOf('oninternet.com.mx') > -1 ? 'production' : 'qa' )  

// -- INIT ---
env = 'production'
// -- END ---        


export const environment = {
    END_POINT_URL:  env == 'production' ? 'https://fkervt4enc.execute-api.us-east-1.amazonaws.com/dev/' : 
                    env == 'qa' ? "https://9cffwctn57.execute-api.us-east-1.amazonaws.com/dev" :
                    env == 'localhost' ? "http://localhost:4500/" :
                    'https://dh1cuhl4fk.execute-api.us-east-1.amazonaws.com/dev/',
    TOKEN_APP: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYWdlX2lkIjoyLCJ0eXBlIjoiQVBQIiwiaWF0IjoxNjM0MTY4NDMwLCJleHAiOjE5NDUyMDg0MzB9.CoZ8uaHYjPTIdjJMI3TlkGckH90gwr87K3gv7niJk30',
    TOKEN_MINUTES_EXPIRES: 300,
    ENV : env
}

export const baseURL =  ( environment.ENV == 'development' ?  `${config.publicPath}json` :  environment.END_POINT_URL )

