<template>
    <div class="py-5 xs:p-6 sm:p-6 w-8/12">
        <div class="flex mb-4">
            <Skeletor width="70%"> </Skeletor>
        </div>
        <div class="flex rounded-3xl justify-start  px-5 py-5 my-2 items-center max-h-13 bg-gray-200" v-for="index in 3" :key="index">
            <Skeletor width="20px" height="20px" circle class="bg-red"> </Skeletor>
            <Skeletor width="90%" class="ml-2"> </Skeletor>
        </div>
    </div>
</template>
<script>
import { Skeletor } from 'vue-skeletor';
export default {
    components: { Skeletor },
}
</script>