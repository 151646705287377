export const SET_HOME_DATA = ( state, payload ) => {
    state.home = payload 
}

export const LOADING_HOME = ( state, boolean ) => {
    state.loading =  boolean
}

export const ERROR_HOME = ( state, message ) => {
    state.error =  message
}

export const SET_PACKAGES_DATA = ( state, payload ) => {
    state.packages =  payload
}


export const SET_PAGE_NUMBER = ( state, payload ) =>{
    state.pageNumber =  payload
}