<template>
<div class="modal-container" >
    <div class="modal">
        <div class="modal-header">
            <a href="#" class="close" @click.prevent="$emit('onClose')">
               <img src="@/assets/images/icon-close.png" alt="close">
            </a>
        </div>
        <div class="modal-body">
            <img src="@/assets/images/icon-sad.png" alt="sad" class="mx-auto my-2">
            <P>Por el momento <span class="font-bold">no tenemos</span> cobertura de <span class="font-bold"> ON Internet </span>  para el domicilio que ingresaste, prueba de nuevo verificando que tu dirección sea correcta o continua con el pago <span class="font-bold"> sin ON Internet. </span> </P>
        </div>
        <div class="modal-footer">
            <button class="btn btn-black" click.prevent="$emit('onClose')"> Probar de nuevo </button>
            <button class="btn btn-green"> Continuar al Pago </button>
        </div>
        <a href="https://secciones.dish.com.mx/avisos-de-privacidad.html" target="_blank" class="underline text-center block my-2" > Al continuar aceptas el Aviso de Privacidad </a>
    </div>
    </div>
</template>
<script>
export default {
    props:['onShow'],
    emits:['onClose'],
    setup() {
        
    },
}
</script>
<style scoped>
    .modal-header{
        @apply relative;
    }
  
    .modal-footer{
        @apply flex align-middle justify-center;
    }
    .modal-body{
        @apply px-5 py-6;
    }
    .modal{
        @apply absolute left-0 top-0 bg-white rounded-2xl px-5 pb-6;
        width: 590px;
        left: 50%;
        transform: translate(-50%, 50%);
    }
    .modal-container{
        @apply  w-full h-screen fixed top-0 left-0 z-0; 
        background: rgba(0, 0, 0, 0.5);
        content:"";
        z-index: 9999999;
    }
    .modal-footer .btn{
        @apply mx-2 my-3
    }
    @media screen and (max-width:767px) {
        .modal {
            width:calc(100% - 40px)
        }
        .close {
            left:calc(50% - 1.5rem)
        }
    }
</style>