import axios from 'axios'
import {endpoints} from '@/api/endpoints.js';
import { baseURL } from '../../api/environment';

const URL =  baseURL
export const callFooterData = async ( {commit, rootState }  ) => {
    try{
        const endpoint = endpoints.footer.getFooter.endpoint
        commit( 'LOADING_FOOTER', true )
        const token    = await rootState.Token.token.dataObj
        const {data}   = await axios.request({
            url:`${endpoint}`,
            method:'get',
            baseURL : URL,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
                },
        })
        commit( 'LOADING_FOOTER', false )
        commit( 'SET_FOOTER_DATA' , data)
    } catch( err ){
        commit( 'LOADING_FOOTER', false )
        commit( 'ERROR_FOOTER', err.message )
    }
}