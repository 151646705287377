<template>
    <div class="p-5 page_registro _registro xs:p-6 sm:p-6">
        <h3 class="text-red uppercase text-2xl"><Skeletor width="50%"> </Skeletor></h3>
        <div class="separator_form"></div>
        <div class="w-full">
            <div class="flex rounded-3xl justify-start  px-5 py-4 my-2 items-center max-h-13">
                <Skeletor width="50px" height="50px" circle class="bg-red"> </Skeletor>
                <Skeletor width="80%" class="ml-2"> </Skeletor>
            </div>
        </div>
        <div class="w-full">
            <div class="flex flex-col justify-between my-5" v-for="index in 3" :key="index">
                <Skeletor width="150px"> </Skeletor>
                <Skeletor width="100%" height="56px" pill class="mt-2"> </Skeletor>
            </div>
            <div class="flex">
                <Skeletor width="160px" height="64px" class="bg-red" pill> </Skeletor>
            </div>
        </div>
    </div>
</template>
<script>
import { Skeletor } from 'vue-skeletor';
export default {
    components: { Skeletor },
}
</script>