<template>
    <div class="w-full mx-auto my-7 mb-12 justify-center flex">
        <div class='flex items-center flex-wrap nav text-center my-20-10 font-semibold text-xl xs:flex-nowrap sm:flex-nowrap'>     
            <router-link to="/registro"  custom v-slot="{ navigate }" >
                <a v-if="folio && complete == false"
                    @click="navigate"  
                    role="link" 
                    :class="{ '_2' : activeStep == 2 , 
                            'passed' : activeStep >= 2,
                            'router-link-active router-link-exact-active' : $route.name == 'Register'
                            }"
                    :style="`${translate}`">
                        <p>1</p>
                        <p>Registro</p>
                </a>
                <span v-else 
                    :class="{ '_2' : activeStep == 2 , 
                            'passed' : activeStep >= 2,
                            'router-link-active router-link-exact-active' : $route.name == 'Register'
                            }"
                    :style="`${translate}`">
                        <p>1</p>
                        <p>Registro</p>
                </span>
            </router-link>
            <router-link to="/pago" custom v-slot="{ navigate }" > 
                 <a v-if="folio && complete == false" 
                    @click="navigate" role="link" 
                    :class="{ '_3' : activeStep == 3 , 
                        'passed' : activeStep >= 3,
                        'router-link-active router-link-exact-active' : $route.name == 'Pago'
                        }" :style="`${translate}`">
                        <p>2</p>
                        <p>Pago</p>
                </a>
                <span v-else
                    :class="{ '_3' : activeStep == 3 , 
                        'passed' : activeStep >= 3,
                        'router-link-active router-link-exact-active' : $route.name == 'Pago'
                    }" :style="`${translate}`">
                        <p>2</p>
                        <p>Pago</p>
                </span>
            </router-link>
            <router-link to="/resumen" custom >
                <span 
                    :class="{ '_4' : activeStep == 4 , 
                        'passed' : activeStep >= 4,
                        'router-link-active router-link-exact-active' : $route.name == 'Resumen'
                        }" :style="`${translate}`">
                     <p>3</p>
                    <p>Resumen</p>
                </span>
            </router-link> 
        </div>
    </div>
</template>
<script>
import { ref, computed } from '@vue/reactivity'
import { useRoute, useRouter } from 'vue-router'
import { inject } from '@vue/runtime-core';
import { mapState, } from 'vuex';

export default {
    props:{
        step: {
            type: String,
            required: true
        },
    }, 
    computed:{
        ...mapState({
            folio: state => ( Object.keys(state.Subscription.subscriber_registration).length != 0 ? state.Subscription.subscriber_registration.dataObj.folio : null ), 
            complete: state => state.Subscription.subscriber_complete
        })
    },
    setup( props ) {
        const route = useRoute()
        const router = useRouter()
        const currentMediaQuery = inject('mq')
        const translate = computed(() => {
            if(currentMediaQuery.current == 'xs' || currentMediaQuery.current == 'sm')
            {
                if (activeStep.value == 2)             
                return 'transform:translateX(100%)'
                else if (activeStep.value == 3) 
                return 'transform:translateX(0%)'
                else if (activeStep.value == 4) 
                return 'transform:translateX(-100%)'
            }
        })
        const activeStep  = ref(2)
        switch( route.name ){
            case 'Register':
                activeStep.value =  2
            break
            case 'Pago':
                activeStep.value =  3
            break
            case 'Resumen':
                activeStep.value =  4
            break
        }


        const handler = ( path ) => {
        
            if(props.folio){
                router.push(path)
            }
        }
        return { activeStep, translate, currentMediaQuery, handler}
    },
}
</script>
<style scoped>
    .nav a p:first-child, .nav span p:first-child{
        border-radius: 100%;
        @apply cursor-pointer justify-center items-center  flex border-2  m-auto w-9 h-9 border-black;
    }
    .nav a p:nth-child(2), .nav span p:nth-child(2){
        @apply text-base;
    }

    .nav a.router-link-active p:nth-child(2), .nav span.router-link-active p:nth-child(2){
        @apply text-base ;
    }
    .nav a p:first-child.active, .nav span p:first-child.active{
        border-radius: 100%;
        @apply cursor-pointer justify-center items-center  flex border-2  m-auto w-9 h-9;
    }
    .nav a p, .nav span p{
        z-index: 1;
        @apply font-medium relative m-3 text-18 text-black;
    }
    .nav a, .nav span{
        min-width: 177px;
        @apply relative;
    }
    .nav a::before, .nav span::before{
        content: " ";
        height: 2px;
        width: 143px;
        top: 17px;
        left: -72px;
        @apply absolute z-0 bg-black;
    }
    .nav a:first-child::before,  .nav span:first-child::before{
        @apply relative;
    }
    .nav a.passed p:first-child, .nav span.passed p:first-child{
        @apply border-green;
    }
    .nav a.passed::before, .nav span.passed::before{
        @apply bg-green;
    }
    .nav a.passed p, .nav span.passed p {
        @apply text-green;
    }
    a.router-link-active p:first-child, span.router-link-active p:first-child {
        @apply bg-green text-white;
    }
    @media screen and (max-width:767px) {
        .nav {
            width:100vw;
            overflow: hidden;
            padding-top:10px;
        }
        .nav a::before, .nav span::before {
            width: 74%;
            left:-37%;
        }
        .nav a, .nav span{
            min-width:33.33%;
        }
        .nav a._1 p:first-child, .nav span._1 p:first-child{
            background:none;
            @apply text-green;
        }
        .nav a._1 p:first-child,  .nav span._1 p:first-child {
            transform:scale(1) !important
        }
        .nav a.router-link-active p:first-child,  .nav span.router-link-active p:first-child{
            transform:scale(1.4)
        }
        .nav a.router-link-active , .nav span.router-link-active {
            z-index: 9;
        }
        .nav a._3.router-link-active .nav a, .nav span._3.router-link-active .nav span {
            transform:translateX(-20px)
        }
    }
    
</style>