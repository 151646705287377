<template>
<div class="hero_banner m-auto lg:container xl:container 2xl:container my-5 xs:my-0 sm:my-0 xs:mx-0 md:m-0 xl:px-10 lg:mt-0 md:mt-0 sm:mt-0 rounded-2xl" data-aos="fade">
    <carousel >
        <slide >
            <div class="w-full rounded-2xl min-h-full flex justify-end flex-wrap relative hero-slide-container lg:rounded-none md:rounded-none xs:w-full xs:rounded-none sm:w-full sm:rounded-none bg-red" >
                <div class="hero-banner-gradient xs:pb-14 sm:pb-14 bg-gradient-to-r from-transparent
                        to-black w-4/12 p-5
                        text-white float-right xl:flex 2xl:flex lg:flex lg:rounded-none md:flex md:justify-center lg:justify-center xl:justify-center 2xl:justify-center md:flex-col lg:flex-col xl:flex-col 2xl:flex-col xs:flex xs:flex-col xs:justify-end sm:flex sm:flex-col sm:justify-end
                        xs:rounded-none xs:bg-gradient-to-b xs:flex-1 xs:text-center sm:rounded-none sm:bg-gradient-to-b sm:flex-1 sm:text-center rounded-2xl md:rounded-none xs:pt-10 xs:w-full xl:w-2/5 md:pl-0 lg:w-3/6 lg:pr-8 md:w-3/6 md:pr-10 xl:pr-12 2xl:pr-12
                        ">
                    <Skeletor width="80%" class="mb-2"/> 
                    <Skeletor width="90%" class="mb-2"/> 
                    <Skeletor width="90%" class="mb-2"/> 
                    <div class="flex my-3">
                    <Skeletor width="50px" class="mb-2 mr-2"/> <Skeletor width="50px" class="mb-2"/> 
                    </div>
                    <Skeletor width="180px" class="mb-2" height="40px" /> 
                </div>
            </div>
        </slide>
        <template #addons>
        <div class="pagination_container"><pagination class="black-bottom-pagination-container xs:left-0 xs:right-0 xs:bottom-3.5 sm:left-0 sm:right-0 sm:bottom-3.5"/></div>
        </template>
    </carousel>
</div>
</template>

<style scoped> 
    .hero-slide-container {
        height: 80vh;
    }
    
    .hero-banner-slide{
        height: 730px;
        min-width: 80vh;
    }
    .hero-slide-container {
        height:639px !important
    }
    .hero-banner-slide-content{
        height: 100%;
    }
    .hero_banner .carousel__viewport {
        @apply xl:rounded-2xl 2xl:rounded-2xl;
        box-shadow:0px 4px 4px rgba(0,0,0,0.25); 
    } 
    @media only screen and (min-width: 1281px) and (max-width: 1366px) {
        .hero_banner {
            padding: 0;
        }
    }
    @media only screen and (min-width: 1025px) and (max-width: 1440px) {
        .hero-slide-container {
            height:639px
        }
    }
    @media only screen and (max-width:767px) {
        .hero-banner-gradient  {
            background:linear-gradient(0deg, rgba(0,0,0,0.7), rgba(0,0,0,0))
        }
         .hero_banner .carousel__viewport {
            box-shadow: none;
        }
        .carousel__viewport {
            border-radius: 0px !important;
        }
    }    
    @media  only screen and (min-width:321px) and (max-width:767px){
        .hero-slide-container{
            height: 90vh;
        }
    }
</style>
<style>
    @media only screen and (max-width:767px) {
        .hero_banner .carousel__viewport {
            border-radius: 0px !important;
        }
    }   
</style>

<script>
import { Skeletor } from 'vue-skeletor';

export default {
    components: { Skeletor },
}
</script>
