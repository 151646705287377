import axios from 'axios';
import {endpoints} from '@/api/endpoints.js';
import { baseURL, environment } from '../../api/environment';
const URL =  baseURL


export const sendSMS = async ( { commit,  rootState }, params ) => {
    try{
        commit( 'SET_LOADING', true )
        const endpoint = endpoints.checkout.sendSMS.endpoint
        commit( 'SET_LOADING', true )
        const token   = await rootState.Token.token.dataObj
        const {data}  = await axios.request({
            url:`${endpoint}`,
            method:'post',
            baseURL : URL,
            responseType: 'json',
            headers: {
                'Authorization': token
            },
            data: {
                telefono : params
            }
        })
        commit( 'SET_SMS' , data)
    } catch( err ){
        commit( 'SET_ERROR_SUBSCRIPTION', err.message )
    } finally {
        commit( 'SET_LOADING', false )
    }
}
export const sendValidateCustomer = async ( {commit, rootState }, params ) => {
    try{
        commit( 'SET_LOADING', true )
        const endpoint = endpoints.checkout.validateCustomer.endpoint
        commit( 'SET_LOADING', true )
        const token   = await rootState.Token.token.dataObj

        const responseData = {
            correo: params.email
        }
        const {data}  = await axios.request({
            url:`${endpoint}`,
            method:'post',
            baseURL : URL,
            responseType: 'json',
            headers: {
                'Authorization': token
            },
            data: {
               ...responseData
            }
        })
        if( data.res ){
            commit( 'SET_CUSTOMER_VALIDATION' , data.res)
        } else {
            commit( 'SET_CUSTOMER_VALIDATION' , data.res)
        }
    } catch( err ){
        commit( 'SET_ERROR_SUBSCRIPTION', err.message )
    } finally {
        commit( 'SET_LOADING', false )
    }
}

export const getGeoPostalCodeDetail = async ( {commit, rootState}  ) => {
    try{
        commit( 'SET_LOADING', true )
        const endpoint = endpoints.checkout.geoStatesCities.endpoint
        const token   = await rootState.Token.token.dataObj
        const {data}  = await axios.request({
            url:`${endpoint}`,
            method:'post',
            baseURL : URL,
            responseType: 'json',
            headers: {
                'Authorization': token
            },
            data: {
                cp : "01010",
                ccolonia: "5"
            }
        })
        commit( 'SET_GEO_POSTAL', data )

    } catch( err ){
        commit( 'SET_ERROR_SUBSCRIPTION', err.message )
    } finally {
        commit( 'SET_LOADING', false )
    }
}

export const sendPostalCode = async ( {commit, rootState}, postalCode  ) => {
    try{
        const endpoint = endpoints.checkout.geoStatesCities.endpoint+`/${postalCode}`
        const token   = await rootState.Token.token.dataObj
        const {data}  = await axios.request({
            url:`${endpoint}`,
            method:'get',
            baseURL : URL,
            // responseType: 'json',
            headers: {
                'Authorization': token
            },
        })
        commit('SET_GEO_ADDRESS', data.dataObj )

    } catch( err ){
        commit( 'SET_ERROR_SUBSCRIPTION', err.message )
    } 
}
export const sendRegisterProduct = async ( {commit, rootState } ) => {
    try{
        commit( 'SET_LOADING', true )
        const endpoint = endpoints.checkout.registerProduct.endpoint
        const subscriber = await rootState.Subscription
        const products = rootState.Products

        const token   = await rootState.Token.token.dataObj
        const requestData = {
            "folio": subscriber.subscriber_registration.dataObj.folio,
            "producto": products.selected.erp_id,
            }
        const {data}  = await axios.request({
            url:`${endpoint}`,
            method:'post',
            baseURL : URL,
            responseType: 'json',
            headers: {
                'Authorization': token
            },
            data: {
                ...requestData
            }
        })
        commit( 'SET_PRODUCT_REGISTRATION' , data.dataObj)
    } catch( err ){
        commit( 'SET_ERROR_SUBSCRIPTION', err.message )
    }
}


export const sendRegisterDirection = async ( {commit, rootState }, params ) => {
    try {
    const endpoint = endpoints.checkout.registerAddress.endpoint
    const token   = await rootState.Token.token.dataObj
    const subscriber = await rootState.Subscription

    console.log( params )
    const requestData = {
        "folio": subscriber.subscriber_registration.dataObj.folio,
        "codigo_postal": params.postalCode,
        "calle": ( params.calle ? params.calle : ' - '),
        "numero_exterior": params.exterior,
        "numero_interior": params.interior,
        "colonia": params.colonia,
        "clave_colonia":  params.ccolonia,
        "municipio": params.municipio,
        "estado": params.ciudad,
        "referencias": ( params.reference ? params.reference : ' - '),
        "entre_calle1": null,
        "entre_calle2": null,
        "cer_google": true
    }
    const {data }  = await axios.request({
        url:`${endpoint}`,
        method:'post',
        baseURL : URL,
        responseType: 'json',
        headers: {
            'Authorization': token
        },
        data: {
            ...requestData
        }
    })


    if( Object.keys( data.dataObj ).length != 0){
        if( data.dataObj.codigo_respuesta == 222 ){
            commit( 'SET_ERROR_SUBSCRIPTION', data.dataObj.mensaje_respuesta )
            commit( 'SET_LOADING', false  )
        } else {
            commit( 'SET_DIRECTION_REGISTRATION' , data)
        }
    } else {
        commit( 'SET_ERROR_SUBSCRIPTION', data.msg )
    }
    
    } catch( err ){
        console.log( 2 )
        commit( 'SET_ERROR_SUBSCRIPTION', err.message )
    } finally {
        commit( 'SET_LOADING', false )
    }
}

export const sendRegisterSubscriber = async ( {commit, rootState, dispatch }, params  ) => {
    try{
        commit( 'SET_LOADING', true )
        const endpoint = endpoints.checkout.registerSubscriber.endpoint
        const user = rootState.Users
        const telefono = user.user_contact.telefono ? user.user_contact.telefono : params.telefono
        const token = rootState.Token.token.dataObj
        const requestData = {
            "nombre": user.user.firstName,
            "apellido_paterno": user.user.lastName,
            "apellido_materno": user.user.middleName,
            "email": user.user_additional_contact.email ?  user.user_additional_contact.email : params.email ,
            "telefono_celular": telefono,
            // "telefono_adicional": {
            //     "tipo": "Casa",
            //     "numero_telefono": telefono
            //     },
            "contacto": {
                "telefono_contacto": {
                    "numero_telefono": telefono,
                    "tipo": "Celular"
                },
                "nombre": user.user.firstName,
                "apellido_paterno": user.user.lastName,
                "apellido_materno": user.user.middleName,
                "tipo": "Familiar,"
                }
            }
        const {data}  = await axios.request({
            url:`${endpoint}`,
            method:'post',
            baseURL : URL,
            responseType: 'json',
            headers: {
                'Authorization': token
            },
            data: {
                ...requestData
            }
        })
        if( Object.keys( data.dataObj ).length != 0){
            if( data.dataObj.codigo_respuesta == 222 ){
                commit( 'SET_ERROR_SUBSCRIPTION', data.dataObj.mensaje_respuesta )
                commit( 'SET_LOADING', false  )
            } else {
                commit( 'SET_SUBSCRIBER_REGISTRATION' , data)
                dispatch( 'sendRegisterProduct' )
            }
        } else {
            commit( 'SET_ERROR_SUBSCRIPTION', data.msg )
        }
    } catch( err ){
        commit( 'SET_ERROR_SUBSCRIPTION', err.message )
        commit( 'SET_LOADING', false )

    }
}

export const sendValidateSMS = async ( {commit,  rootState }, params ) => {
    try{
        commit( 'SET_LOADING', true )
        const endpoint = endpoints.checkout.validateSMS.endpoint
        const token = rootState.Token.token.dataObj
        const telefono = await rootState.Users.user_contact.telefono
        const codigo = params.code
        const {data}  = await axios.request({
            url:`${endpoint}`,
            method:'post',
            baseURL : URL,
            responseType: 'json',
            headers: {
                'Authorization': token
            },
            data: {
                telefono: telefono,
                codigo : codigo
            }
        })
        if( data.res ){
                if( data.dataObj.validacion.toUpperCase() == "FALSE" ) {
                    commit( 'SET_ERROR_SUBSCRIPTION', data.msg )
                    commit( 'SET_SMS_VALIDATION' , null)
                } else {
                    commit( 'SET_SMS_VALIDATION' , data.res)
                }
        } else {
            commit( 'SET_SMS_VALIDATION' , data.res)
        }
    } catch( err ){
        commit( 'SET_ERROR_SUBSCRIPTION', err.message )
    } finally {
        commit( 'SET_LOADING', false )
    }
}

export const generateRandomOrderId = ( ) =>{
   return Date.now()
}

export const generateBarcode = async ( {commit,  rootState  } ) => {
    try{
    commit( 'SET_LOADING', true )
        const token = rootState.Token.token.dataObj
        const telefono =  rootState.Users.user_contact.telefono
        const endpoint = endpoints.checkout.generateBarcode.endpoint
        const {data}  = await axios.request({
            url:`${endpoint}`,
            method:'post',
            baseURL : URL,
            responseType: 'json',
            headers: {
                'Authorization': token
            },
            data:{
                telefono : telefono
            }
        })        
        commit( 'SET_BARCODE', data.dataObj)
    } catch ( err ){
        commit( 'SET_ERROR_SUBSCRIPTION', err.message )
    } finally {
        commit( 'SET_LOADING', false )
    }
}
export const generateSignaturePay  = async ( {commit,  rootState  }  ) => { 
    try{
        commit( 'SET_LOADING', true )
        const token = rootState.Token.token.dataObj
        const product = rootState.Products.information
        const total = parseInt( parseFloat( parseInt(product.suscripcion) * 100 ).toFixed(0) )
        const orderId =  generateRandomOrderId()
        const endpoint = endpoints.checkout.generateSignaturePay.endpoint + `/${total}/${orderId}`
    
        const {data}  = await axios.request({
            url:`${endpoint}`,
            method:'get',
            baseURL : URL,
            responseType: 'json',
            headers: {
                'Authorization': token
            },
        })

        data.dataObj.orderId = orderId
        data.dataObj.total = total
        
        commit( 'SET_REGISTER_SIGNATURE', data )

    } catch(err ){
        commit( 'SET_ERROR_SUBSCRIPTION', err.message )
    } finally {
        commit( 'SET_LOADING', false )
    }
}

const getCardNumber = (  cardnumber ) => {
    return cardnumber.replace(/ /g,'').replace(/-/g,'')
}

const getPhoneNumber = ( phonenumber ) => {
    return phonenumber.replace(/ /g,'').replace(/-/g,'')
}

const getCardType = ( cardnumber ) => {
    const firstDigit = cardnumber.substring(0, 1)
    let cardType = ""
    switch(  firstDigit ){
        case '3':
            cardType = "AMEX"
        break;
        case '4':
            cardType = "VISA"
        break;
        case '5':
            cardType = "Master Card"
        break;
        case '6':
            cardType = "DISCOVERY"
        break;    
    }
    return cardType
}
export const setRegisterMethod = async ( {commit, getters, rootState  } , params  ) => {
    try{
        commit( 'SET_LOADING', true )
        const token   =  rootState.Token.token.dataObj
        const endpoint = endpoints.checkout.registerMethod.endpoint
        const subscriber = await getters.getFolio
        let  requestData = {}
        switch (params.paymentMethod) {
            case 'efectivo':
                requestData = {
                    "folio": subscriber.dataObj.folio,
                    "metodo_pago": "Efectivo",
                    "pago_suscripcion": "centro-pago"
                }
                break;
            case 'paypal':
                requestData = {
                    "folio": subscriber.dataObj.folio,
                    "metodo_pago": "Efectivo",
                    "pago_suscripcion": "PayPal"
                }
                break;
            case 'telmex':
                requestData = {
                    "folio": subscriber.dataObj.folio,
                    "metodo_pago": "Recibo Telmex",
                    "numero_telmex": getPhoneNumber(params.telmex)
                }
                break;
            case 'card':
                requestData = {
                    "folio": subscriber.dataObj.folio,
                    "metodo_pago": "Tarjeta de crédito",
                    "nombre_titular_tarjeta": params.cardName,
                    "tipo_tarjeta": getCardType(params.card),
                    "numero_tarjeta": getCardNumber(params.card),
                    "pago_suscripcion": "VICOMM"
                }
                break;
        }
        
        const {data}  = await axios.request({
            url:`${endpoint}`,
            method:'post',
            baseURL : URL,
            responseType: 'json',
            headers: {
                'Authorization': token
            },
            data: {
                ...requestData
            }
        })
 
     if( Object.keys( data.dataObj ).length != 0){
        if( data.dataObj.codigo_respuesta == 222 ){
            commit( 'SET_ERROR_SUBSCRIPTION', data.dataObj.mensaje_respuesta )
            commit( 'SET_LOADING', false  )
            return data;
        } else{
            commit( 'SET_REGISTRATION_METHOD' , data)
            return data;
        }
     } else {
        commit( 'SET_ERROR_SUBSCRIPTION', data.msg )
        commit( 'SET_LOADING', false  )
     }
    } catch( err ){
        commit( 'SET_ERROR_SUBSCRIPTION', err.message )
    }finally{
        commit( 'SET_LOADING', false )
    }
}

export const sendStatusShipping = async ({ commit, getters, rootState }) => {
    try {
        commit('SET_LOADING', true)
        const token = rootState.Token.token.dataObj
        const endpoint = endpoints.checkout.sendStatusShipping.endpoint
        const subscriber = await getters.getFolio
        const { data } = await axios.request({
            url: `${endpoint}`,
            method: 'post',
            baseURL: URL,
            responseType: 'json',
            headers: {
                'Authorization': token
            },
            data: {
                "folio": subscriber.dataObj.folio,
            }
        })

        commit('SET_SHIPPING_INFORMATION', data.dataObj)
    } catch (err) {
        commit('SET_LOADING', false)
        commit('SET_ERROR_SUBSCRIPTION', err.message)
    } finally {
        commit('SET_LOADING', false)
    }

}

export const sendShipping = async ({ commit, rootState, getters }) => {
    try {
        commit('SET_LOADING', true)
        const token = rootState.Token.token.dataObj
        const endpoint = endpoints.checkout.sendShipping.endpoint
        const subscriber = await getters.getFolio

        const { data } = await axios.request({
            url: `${endpoint}`,
            method: 'post',
            baseURL: URL,
            responseType: 'json',
            headers: {
                'Authorization': token
            },
            data: {
                "folio": subscriber.dataObj.folio,
            }
        })
        if (Object.keys(data.dataObj).length != 0) {
            if (data.dataObj.codigo_respuesta == 222) {
                commit('SET_ERROR_SUBSCRIPTION', data.dataObj.mensaje_respuesta)
                commit('SET_LOADING', false)
            } else {
                return true
            }
        } else {
            commit('SET_LOADING', false)
            commit('SET_ERROR_SUBSCRIPTION', data.msg)
        }
    } catch (err) {
        commit('SET_LOADING', false)
        commit('SET_ERROR_SUBSCRIPTION', err.message)
    } finally {
        commit('SET_LOADING', false)
    }
}

export const setStatus = async ( {commit,  } ) => {
    try{
        commit( 'SET_LOADING', true )
        commit( 'SET_REGISTRATION' , null)
    } catch( err ){
        commit( 'SET_LOADING', false )
        commit( 'SET_ERROR_SUBSCRIPTION', err.message )
    }
}

export const setRegistration = async ( {commit,  } ) => {
    try{
        commit( 'SET_LOADING', true )
        commit( 'SET_REGISTRATION' , null)
    } catch( err ){
        commit( 'SET_LOADING', false )
        commit( 'SET_ERROR_SUBSCRIPTION', err.message )
    }
}

export const callCellularCode  = async ( {commit,  } ) => {
    try{
        commit( 'SET_LOADING', true )
        commit( 'SET_REGISTRATION' , null)
    } catch( err ){
        commit( 'SET_LOADING', false )
        commit( 'SET_ERROR_SUBSCRIPTION', err.message )
    }
}
export const sendReferencePayment = async ( {commit, rootState}) => {
    try{
        commit( 'SET_LOADING', true )
        const token = rootState.Token.token.dataObj
        const telefono =  rootState.Users.user_contact.telefono
        const endpoint = endpoints.checkout.sendReferencePayment.endpoint
        const {data}  = await  axios.request({
            url:`${endpoint}`,
            method:'post',
            baseURL : URL,
            responseType: 'json',
            headers: {
                'Authorization': token
            },
            data:{
                telefono : telefono
            }
        }) 
        if( data.dataObj.statusCode == 1200) {
            commit( 'SET_ERROR_SUBSCRIPTION', data.dataObj.message )
        } else {
            commit( 'SET_BARCODE_REFERENCE', data)
        }
    } catch ( err ){
        commit( 'SET_ERROR_SUBSCRIPTION', err.message )
    } finally {
        commit( 'SET_LOADING', false )
    }
}
export const validateTelmex = async ( { commit, rootState }, params ) => {
    try{
        commit( 'SET_LOADING', true )
        const token   =  rootState.Token.token.dataObj
        const telmex = params.telmex.replace(/-/g,'').replace(/ /g,'')
        const { data }  = await axios.request({
            method:'post',
    // -- INIT ---
    baseURL:'https://eco-digital-tlmx.dish.com.mx/consulta_telmex', 
    // -- END ---

            responseType: 'json',
            headers: {
                'Authorization': token
            },
            data: {
                numeroTelefono: telmex,
                generarFolio: false
            }
        })
   
        if( data){
            const codigoRespuesta = data.codigoRespuesta 
            if(codigoRespuesta != 222 ){
                commit('SET_TELMEX_NUMBER', data.numTelefonico )
            } else{
                commit( 'SET_ERROR_SUBSCRIPTION', data.mensajeRespuesta  )
            }
        }
    } catch( err ){
        commit( 'SET_ERROR_SUBSCRIPTION', err.message )
    } finally{
        commit( 'SET_LOADING', false )
    }

}

export const sendCustomerService = async( {commit, rootState}, params  ) => {
    try{
        commit( 'SET_LOADING', true )
        const endpoint = endpoints.checkout.sendCustomerServiceForm.endpoint
        const token   =  rootState.Token.token.dataObj
        const request  =  {
            form : 'contact',
            name : params.name, 
            phone : params.phone,
            email : params.email,
            city: params.city,
            postal: params.postal,
            dish: true,
            tecnologia: params.tecnologia ,
            aviso: ''
        }

        const {data}  = await  axios.request({
            url:`${endpoint}`,
            method:'post',
            baseURL : URL,
            responseType: 'json',
            headers: {
                'Authorization': token
            },
            data:{
                ...request
            }
        }) 
        if( data.dataObj.statusCode == 1200) {
            commit( 'SET_ERROR_SUBSCRIPTION', data.dataObj.message )
        } else {
            if( data.res ){
                return true
            } else {
                commit( 'SET_ERROR_SUBSCRIPTION', data.dataObj.message )
                return false
            }
        }
    } catch ( err ){
        commit( 'SET_ERROR_SUBSCRIPTION', err.message )
    } finally {
        commit( 'SET_LOADING', false )
    }
} 

export const sendEmailClient = async( { commit,rootState }, params ) =>{
    try{
        commit( 'SET_LOADING', true )
        
        const endpoint = endpoints.checkout.sendEmailClient.endpoint
        const token   =  rootState.Token.token.dataObj

        const {data}  = await  axios.request({
            url:`${endpoint}`,
            method:'post',
            baseURL : URL,
            responseType: 'json',
            headers: {
                'Authorization': token
            },
            data:{
                email : params
            }
        }) 
        if( data.dataObj.statusCode == 1200) {
            commit( 'SET_ERROR_SUBSCRIPTION', data.dataObj.message )
        } else {
            commit( 'SET_BARCODE_REFERENCE', data)
        }
    } catch ( err ){
        commit( 'SET_ERROR_SUBSCRIPTION', err.message )
    } finally {
        commit( 'SET_LOADING', false )
    }
}




export const tranEnviadaVicomm = async ({ commit, rootState }, params) => {
    try {
        commit('SET_LOADING', true)
        const env = environment.ENV
        console.log("amb en uso",env)

        let api1 = ( env=='production' ? 'https://481vwlnrk7.execute-api.us-east-1.amazonaws.com/Prod/tranEnviadaVicomm' : 
        'https://481vwlnrk7.execute-api.us-east-1.amazonaws.com/dev/tranEnviadaVicomm' )


        console.log("Api val es:",api1)
        const endpoint = api1;      
        // const endpoint = 'https://481vwlnrk7.execute-api.us-east-1.amazonaws.com/dev/tranEnviadaVicomm';
        commit('SET_LOADING', true)
        const token = await rootState.Token.token.dataObj
        const { data } = await axios.request({
            url: `${endpoint}`,
            method: 'post',
            baseURL: URL,
            responseType: 'json',
            headers: {
                'Authorization': token
            },
            data: {
                
                    "cliente": params.cliente,
                    "orderId":params.orderId,
                    "canalPago":params.canalPago,
                    "correo":params.correo,
                    "telefono":params.telefono,
                    "nombre":params.nombre,
                    "monto":params.monto,
                    "canalOrigen":params.canalOrigen,
                    "idConcepto": ""
            
                    
            }
        })     
         commit('SET_Tran_Enviada_ViComm', data)
         return data
    } catch (err) {
        // commit('SET_ERROR_SUBSCRIPTION', err.message)
    } finally {
        commit('SET_LOADING', false)
    }
}



export const tranEnviadaVicomm2 = async ({ commit, rootState }, params) => {
    try {
        commit('SET_LOADING', true)
        const env = environment.ENV
        console.log("amb en uso",env)

        let api1 = ( env=='production' ? 'https://481vwlnrk7.execute-api.us-east-1.amazonaws.com/Prod/tranRecibidaVicomm' : 
        'https://481vwlnrk7.execute-api.us-east-1.amazonaws.com/dev/tranRecibidaVicomm' )

        console.log("Api val es:",api1)
        const endpoint = api1; 
        // const endpoint = 'https://481vwlnrk7.execute-api.us-east-1.amazonaws.com/dev/tranRecibidaVicomm';
        commit('SET_LOADING', true)
        const token = await rootState.Token.token.dataObj
        const { data } = await axios.request({
            url: `${endpoint}`,
            method: 'post',
            baseURL: URL,
            responseType: 'json',
            headers: {
                'Authorization': token
            },
            data: {
                
        "cliente": params.cliente,
        "orderId": params.orderId,
        "canalPago": params.canalPago,
        "currentStatus": params.currentStatus,
        "authCode": params.authCode,
        "monto": params.monto,
        "reference": params.reference,
        "productDesc": params.productDesc,
        "canalOrigen": params.canalOrigen,
        "idConcepto": ""
            
                    
            }
        })     
         commit('SET_Tran_Enviada_ViComm2', data)
         return data
    } catch (err) {
        // commit('SET_ERROR_SUBSCRIPTION', err.message)
    } finally {
        commit('SET_LOADING', false)
    }
}

export const getEffectivoPrice = async ({ commit, rootState }) => {
    try {
        commit('SET_LOADING', true)
        commit('LOADING_PACKAGES', true)

        const token = rootState.Token.token.dataObj
        const postal_code = rootState.Subscription.user_registration.postalCode
        const endpoint = endpoints.checkout.subscribeEffectivo.endpoint
        //const endpoint = 'suscripciones'
        //const URL_OVERWRITE = "https://19xok6ua3k.execute-api.us-east-1.amazonaws.com/Prod/"
        const { data } = await axios.request({
            url: `${endpoint}`,
            method: 'post',
            baseURL: URL,
            responseType: 'json',
            headers: {
                'Authorization': token,
            },
            data: {
                cp: postal_code
            }
        })

        if (Object.keys(data.dataObj.datos).length != 0) {
            if (data.dataObj.codigo == 222) {
                commit('SET_ERROR_SUBSCRIPTION', data.dataObj.mensaje_respuesta)
                commit('SET_LOADING', false)
                return false
            } else {
                if (Object.keys(data.dataObj.datos).length != 0) {
                    commit('SET_EFFECTIVO_PRICE', data.dataObj.datos)
                    return true
                } else {
                    return false
                }
            }
        } else {
            commit('SET_ERROR_SUBSCRIPTION', data.dataObj.mensaje)
            return false
        }

    } catch (err) {
        commit('SET_LOADING', false)
        commit('LOADING_PACKAGES', false)
    } finally {
        commit('SET_LOADING', false)
        commit('LOADING_PACKAGES', false)
    }
}



export const saveIdPaypal = async ( {commit }, params ) => {
    try{
        commit( 'SET_ID_PAYPAL', params )
    } catch( err ){
        commit( 'SET_ERROR', err.message )
    } finally {
        commit( 'SET_LOADING', false )
    }
}

export const saveSuscEfectivo = async ( {commit }, params ) => {
    try{
        commit( 'SET_SUSC_EFECTIVO', params )
    } catch( err ){
        commit( 'SET_ERROR', err.message )
    } finally {
        commit( 'SET_LOADING', false )
    }
}






export const tranOrderId = async ({ commit, rootState }, params) => {
    try {
        commit('SET_LOADING', true)
        const env = environment.ENV
        console.log("amb en uso",env)

        let api1 = ( env=='production' ? 'https://481vwlnrk7.execute-api.us-east-1.amazonaws.com/Prod/getorder_paypal_ecodig' : 
        'https://481vwlnrk7.execute-api.us-east-1.amazonaws.com/dev/getorder_paypal_ecodig' )


        console.log("Api val es:",api1)
        const endpoint = api1;      
        // const endpoint = 'https://481vwlnrk7.execute-api.us-east-1.amazonaws.com/dev/tranEnviadaVicomm';
        commit('SET_LOADING', true)
        const token = await rootState.Token.token.dataObj
        const { data } = await axios.request({
            url: `${endpoint}`,
            method: 'post',
            baseURL: URL,
            responseType: 'json',
            headers: {
                'Authorization': token
            },
            data: {
                
                    "folio": params.folio,
                    "monto":params.monto
            
                    
            }
        })     
         commit('SET_Order_Id', data)
         return data
    } catch (err) {
        // commit('SET_ERROR_SUBSCRIPTION', err.message)
    } finally {
        commit('SET_LOADING', false)
    }
}








export const tran_paypal = async ({ commit, rootState }, params) => {
    try {
        commit('SET_LOADING', true)
        const env = environment.ENV
        console.log("amb en uso",env)

        let api = ( env=='production' ? 'https://481vwlnrk7.execute-api.us-east-1.amazonaws.com/Prod/insertatransaccion_paypal_ecodigital' : 
        'https://481vwlnrk7.execute-api.us-east-1.amazonaws.com/dev/insertatransaccion_paypal_ecodigital' )


        console.log("Api val es:",api)
        const endpoint = api;      
        commit('SET_LOADING', true)
         const token = await rootState.Token.token.dataObj
        const { data } = await axios.request({
            url: `${endpoint}`,
            method: 'post',
            baseURL: URL,
            responseType: 'json',
            // headers: {
            //     'Authorization': token
            // },
            data: {
                
                    "folio": params.folio,
                    "monto": params.monto,
                    "orderIdDish": params.orderIdDish,
                    "orderIdPaypal": params.orderIdPaypal,
                    "estatus": params.estatus,
                    "request": params.request,
                    "response": params.response,
                    "canalPago": params.canalPago
                
            }
        })     
         commit('SET_Tran_paypal', data)
         return data
    } catch (err) {
        // commit('SET_ERROR_SUBSCRIPTION', err.message)
    } finally {
        commit('SET_LOADING', false)
    }
}