export const SET_PUBLIC_TOKEN = ( state, payload ) => {
    state.token = payload 
}

export const LOADING_TOKEN = ( state, boolean ) => {
    state.loading =  boolean
}

export const ERROR_TOKEN = ( state, message ) => {
    state.error =  message
}

export const SET_PAGE_DATA = ( state, payload ) => {
    state.page =  payload
}
