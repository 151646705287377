<template>
  <div class="home">
    <Header> </Header>
    <div class="flex flex-col justify-center items-center _404 xs:min-h-screen sm:min-h-screen">
        <div>
          <img src="@/assets/images/404.svg" alt="404" class="mb-5 mt-10 m-auto xs:px-6 sm:px-6">
          <p class="text-center text-18 xs:w-10/12 sm:w-10/12 xs:m-auto sm:m-auto">La página que estás buscando no está disponible por el momento, vuelve a intentarlo más tarde.</p>
        </div>
        <router-link tag="button" class="btn btn-green mt-12 text-center" :to="{ name: 'Home'}">Regresar</router-link>
    </div>
    <Footer> </Footer> 
  </div>
</template>
<style scoped>
  ._404 {
    height:70vh
  }
  p {
    max-width:650px;
  }
</style>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'PageNotFound',
  components: {
    Header,
    Footer,
  },
}

</script>
