export const SET_NAVBAR_DATA = (state, payload) => {
    state.navbar = payload
}

export const LOADING_NAVBAR = (state, boolean) => {
    state.loading = boolean
}

export const ERROR_NAVBAR = (state, message) => {
    state.error = message
}

export const SET_OPEN_MOBILE_MENU = (state, boolean) => {
    state.isOpenMenu = boolean
}

export const SET_SCROLLABLE = (state, payload) => {
    if(payload.currentMediaQuery == 'xs' || payload.currentMediaQuery == 'sm' || payload.currentMediaQuery == 'md'){
        return
    }
    else {
        if(payload.boolean == false) {
            state.bodyOverflow = document.documentElement.style.overflow = 'hidden'
        }
        else {
            state.bodyOverflow = document.documentElement.style.overflow = 'auto'
        }
    }
}
export const SET_ISSHOWMODAL = (state, payload) => {
    state.modal.isShowModal = payload
}
export const SET_ISSHOWFOOTER = (state, payload) => {
    if(state.currentMediaQuery == 'xs' || state.currentMediaQuery == 'sm') 
    state.isShowFooter = payload
    else
    return
    
}
export const SET_ISREGISTERPAGE = (state, payload) => {
    state.isRegisterPage = payload
}
export const STOP_INTERVAL_MOBILE = (state) => {
    state.modal.start = false
    state.modal.stop = true
    state.modal.mobileBodyHeight = `height:100%; overflow:auto;`
    setTimeout(()=>{
        window.scrollTo(0,state.scrollPosition)
    },0) 
}
export const SET_SCROLL_POSITION = (state, payload) => {
    state.scrollPosition = payload
}
export const SET_MOBILE_BODY_HEIGHT = (state, payload) => {
    state.currentMediaQuery = payload.currentMediaQuery
    state.modal.bodyRef = payload.bodyRef
    state.modal.bodyIsRef = payload.bodyIsRef
    if(payload.currentMediaQuery == 'xs' || payload.currentMediaQuery == 'sm' || payload.currentMediaQuery == 'md') { 
       // console.log('mobile')
        if(state.isRegisterPage == true) {
            state.isShowFooter = false
          //  console.log('mobile')
        }
        if(state.isRegisterPage == true && payload.currentMediaQuery == 'md') {
            state.isShowFooter = true
         //   console.log('tablet')
        }
        if(payload.bodyRef != null){
            //console.log('IF', payload.currentMediaQuery, payload.bodyRef, payload.bodyRef.clientHeight, payload.bodyIsRef, payload.isRegisterPage) // TODO:REMOVE SOON
            state.modal.start = true
            state.modal.stop = false
            const timer = setInterval(()=> {
                if(state.modal.stop){
                    clearInterval(timer);
                 //   console.log('mobile height watching STOPPED, getter: ', state.modal.mobileBodyHeight) 
                    return
                }
                state.modal.start = false
                state.modal.stop = true
                state.modal.mobileBodyHeight =
                payload.bodyIsRef && payload.bodyRef != null
                    ? payload.bodyRef.clientHeight
                    : '';
                   // console.log('mobile height watching STARTED, getter: ', state.modal.mobileBodyHeight) 
            }, 300)
        }
        else {
            state.modal.start = false
            state.modal.stop = true
        }  
    }
}