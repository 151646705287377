export const getHomeData = ( state) => {
    return state.home
}

export const getFrankyIframe = ( state ) => {
    try{

        if(state.franky){
        if( Object.keys( state.franky ).length != 0 ) {
        let dataObj  = state.franky.dataObj
        if( dataObj ){
            dataObj = dataObj.content_page 
            let iframe  = dataObj.find( ( { content } ) => { 
                if ( content ){
                    if( content.template.name ){
                        return content.template.name.toLowerCase()  == 'iframe sencillo'   
                    }
                }
            })
            return  iframe.content.content_values[0]
        }
    }
    }
    } catch( err) {
        console.log( err )
    }
}

export const getHomeHeroBanner = ( state) => {
    try{
        let dataObj     = state.home.dataObj
        if( dataObj ){
            dataObj = dataObj.content_page 
            let banner  = dataObj.find( ( { content } ) => { 
                if ( content ){
                    if( content.template ){
                        return content.template.name.toLowerCase()  == 'hero'   
                    }
                }
            })
            if( banner ){
                let group   = banner.content.content_values_grouped 
                let data  = { list : [] }
                    group.forEach( (key  ) => {
                        let group =  {}
                        key.forEach(  ( key  ) =>{
                            group[key.field] = key.value
                        }) 
                        data['list'].push(group)
                        data['title'] = banner.content.titulo
                })
                
                return data
            }
            
        }
    } catch( err) {
        console.log( err )
    }
}
export const getHomePromotionsTitle = ( state) => {
    try{
        let dataObj     = state.home.dataObj
        if( dataObj ){
            dataObj = dataObj.content_page 
            let title  = dataObj.find( ( { content } ) => {  
                    if(content.template) {
                        return content.template.name.toLowerCase()  == 'titulo simple'
                    }
                })
            if( title ){
                let data   = title.content.content_values[0]
                return data
            }
        }
    } catch( err) {
        console.log( err )
    }
}

export const getHomePromotionsBanner = ( state) => {
    try{
        let dataObj     = state.home.dataObj
        if( dataObj ){
            dataObj = dataObj.content_page 
            let banner  = dataObj.find( ( { content } ) => { 
                if(content.template) {
                    return content.template.name.toLowerCase()  == 'promociones'
                }
            })
            if( banner ) {
            let group   = banner.content.content_values_grouped 
            
            let data  = { list : [] }
                group.forEach( (key  ) => {
                    let group =  {}
                    key.forEach(  ( key  ) =>{
                        group[key.field] = key.value
                    }) 
                    data['list'].push(group)
                    data['title'] = banner.content.titulo
               })
               
            return data
            }
        }
    } catch( err) {
        console.log( err )
    }
}
export const getPackageDataList = ( state, getters, rootState ) => {
    try{
        let dataObj = rootState.Home.packages.dataObj
        let title   = ""
        if( dataObj ){
            dataObj = dataObj.content_page 
            let packages  = dataObj.filter( ( { content } ) => { 
                if(content.template) {
                    return content.template.name.toLowerCase()  == 'grupo sencillo'
                }
            })
            let p = [ ]
            
            packages.map( ( {content}  )  => {
                let con = content.content_values 
                con.forEach( (key, ) =>{
                   key.custom_value.forEach( (keys, idxs ) =>{
                        p.push({ package : key.custom_value[idxs].package  , serialize : JSON.parse(  key.custom_value[idxs].package.value ) })
                    })
                })
            })
            let data  = { list : p, title: title }
            return data
        }
    } catch( err) {
        console.log( err )
    }
}


export const getHomePromotionsSlim = ( state ) => {
    try{
        let dataObj     = state.home.dataObj
        if( dataObj ){
            dataObj = dataObj.content_page 
            let banner  = dataObj.find( ( { content } ) => { 
                if(content.template) {
                    return content.template.name.toLowerCase()  == 'slim'
                }
            })
            if( banner ){
                let group   = banner.content.content_values_grouped 
                let data  = { list : [] }
                    group.forEach( (key  ) => {
                        let group =  {}
                        key.forEach(  ( key  ) =>{
                            group[key.field] = key.value
                        }) 
                        data['list'].push(group)
                        data['title'] = banner.content.titulo
                })
                
                return data
            }
        }
    } catch( err) {
        console.log( err )
    }
}


const getIframeData =  ( contentData ) => {
    return  contentData.content.content_values[0]
}
const getPromotionsData =  ( contentData ) => {

    let group   = contentData.content.content_values_grouped      
    let data  = { list : [] }
        group.forEach( (key  ) => {
            let group =  {}
            key.forEach(  ( key  ) =>{
                group[key.field] = key.value
            }) 
            data['list'].push(group)
            data['title'] = contentData.content.titulo
       })
       
    return data
    
}
const getPackageData = ( contentData ) => {
    let p = [ ]
    let legal = ""
    let con = contentData.content.content_values 
        con.forEach( (key ) =>{
            legal =  key.custom_value.legal
           key.custom_value.packages.forEach( (keys ) =>{
               let data = { package : keys.package ,  group : keys }
                if( keys.package.value_on ){
                    data.serialize  =  JSON.parse(  keys.package.value_on )
                }
                if( keys.values_info ){
                    data.technologies = JSON.parse(  keys.values_info.value ) 
                    data.technologies.section =  keys.values_info.section 
                }
                p.push( data ) 
            })
        })
    
    let data  = { list : p, title: contentData.content.titulo, legal : legal   }
    return data

}
const getListData =  ( contentData ) => {
    let group   = contentData.content.content_values_grouped 
    let data  = { list : [] }
        group.forEach( (key  ) => {
            let group =  {}
            key.forEach(  ( key  ) =>{
                group[key.field] = key.value
            }) 
            data['list'].push(group)
            data['title'] = contentData.content.titulo
       })
    return data
}
const getTitleData  =  ( contentData ) => {
    let group   = contentData.content.content_values 
    let data  = { list : [] }
        group.forEach( ( key  ) => {
            data[key.field] = key.value
       })
    return data
}
const getTextIntro = ( contentData ) => {
    let group   = contentData.content.content_values 
    let data  = { list : [] }
        group.forEach( ( key  ) => {
            data[key.field] = key.value
       })

    return data
}
const getGroupPackageData = ( contentData ) => {
    try{
        const packages   = contentData.content.content_values
        const title = contentData.content.titulo
        if( packages ){
            let tabs = []
            packages.forEach( (key, index  ) =>{
                if( key.custom_value ){
                    key.custom_value.forEach( (keys, idx  ) =>{
                        if ( keys ){
                            keys.forEach( ( k,i ) => {
                                if( k ){                                   
                                    k.packages.forEach( (a, b) => {
                                        if( a.package.value_on ){
                                            packages[index].custom_value[idx][i].packages[b].serialize =  JSON.parse(  a.package.value_on) 
                                        } else if( a.package.value ){
                                            packages[index].custom_value[idx][i].packages[b].serialize =  JSON.parse(  a.package.value ) 

                                        }
                                        if( a.values_info ){
                                            if( a.values_info.value ){
                                                packages[index].custom_value[idx][i].packages[b].technologies = JSON.parse(  a.values_info.value ) 
                                                packages[index].custom_value[idx][i].packages[b].technologies.section  =  a.values_info.section
                                            } 
                                        }

                                    })
                                }
                            })
                        }
                       
                        if( key.value){
                            tabs = JSON.parse( key.value )
                        }
                    })
                }
            })
            const p = packages.shift() 
            let list = []
            if( p ){
                list = p.custom_value
            }
             return { list , title, tabs }
        }
    } catch( err) {
        console.log( err )
    }
}

const getTextColumn = ( contentData ) => {
    let group   = contentData.content.content_values_grouped 
    let data  = { list : [] }
        group.forEach( (key  ) => {
            let group =  {}
            key.forEach(  ( key  ) =>{
                group[key.field] = key.value
            }) 
            data['list'].push(group)
            data['title'] = contentData.content.titulo
       })
    return data
}

export  const getComponentData = ( state, getters, rootState ) => {
    
    let content = rootState.Home.home.dataObj.content_page
    let data = []
    if( content && content.length > 0 ){
        content.forEach( ( contentData  ) =>{
            switch(contentData.content.template.name.toLowerCase() ){
                case 'iframe sencillo':
                    data.push({ name : 'Iframe', data : getIframeData( contentData ) })

                break;
                case 'promociones':
                    data.push({ name : 'CarouselPromotions', data : getPromotionsData ( contentData ) })
                break;

                case  'grupo sencillo':
                    data.push({ name : 'CarouselONPackages', data : getPackageData( contentData ) })

                break;

                case 'slim':
                    data.push({ name : 'CarouselPromotionsBanner', data : getListData( contentData ) })
                break

                case 'hero':
                    data.push({ name : 'CarouselHeroBanner', data : getListData( contentData ) })
                break;

                case 'titulo simple':
                    data.push({ name : 'TitleComponent', data : getTitleData( contentData ) })
                break;

                case 'texto intro':
                    data.push({ name : 'TextIntro', data : getTextIntro( contentData ) })
                break;
                
                case 'plantilla columnas':
                    data.push({ name : 'TextColumns', data : getTextColumn( contentData ) })
                break;
                
                case 'grupo completo':
                    data.push({ name : 'CarouselGroupedONPackages', data : getGroupPackageData( contentData, content) })
            }
        })
    }
        return data
    }