export const SET_USER_DATA = ( state, payload ) => {
    state.user = payload 
}
export const SET_USER_CONTACT_ADDITIONAL_DETAILS = ( state, payload ) => {
    state.user_additional_contact = payload 
}

export const SET_USER_CONTACT_DETAILS = ( state, payload ) => {
    state.user_contact = payload 
}
export const LOADING_USER = ( state, boolean ) => {
    state.loading =  boolean
}

export const ERROR_USER = ( state, message ) => {
    state.error =  message
}