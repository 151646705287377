<template>
    <div>
        <div class="bg-black">
            <div class="lg:container 2xl:container xs:px-5 px-8 pt-7 2xl:pt-px-7 xs:py-10 mx-auto xs:pb-5 sm:pb-5 pb-5">
                <Skeletor width="60%"> </Skeletor>  
                <div class="flex  mt-5">
                    <Skeletor width="50%" height="100px" class="mr-3"> </Skeletor>  
                    <Skeletor width="30%" height="100px" class="mr-3"> </Skeletor>  
                </div>
                <div class="flex mt-5 mb-5">
                    <Skeletor width="50%" class="mr-3"> </Skeletor>  
                    <Skeletor width="40%" class="mr-3"> </Skeletor>  
                    <Skeletor width="10%" class="mr-3"> </Skeletor>  
                </div>
            </div>
        </div>
        <div class=" bg-gray-800">
            <p class="2xl:container 2xl:mx-auto text-gray-400 p-8 xs:text-xs xs:text-left xs:leading-5 xs:pt-4 xs:p-5 sm:text-xs sm:text-left sm:leading-5 sm:pt-4 sm:p-5"  >
                <Skeletor width="100%"> </Skeletor>  
                <Skeletor width="100%"> </Skeletor>  
                <Skeletor width="100%"> </Skeletor>  
                <Skeletor width="100%"> </Skeletor>  
                <Skeletor width="100%"> </Skeletor>  
                <Skeletor width="100%"> </Skeletor> 
            </p>
        </div>
    </div>    
</template>
<script>
import { Skeletor } from 'vue-skeletor';

export default {
    components: { Skeletor },
}
</script>
