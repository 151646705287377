import axios from 'axios'
// import { baseURL } from '../../api/environment';
const URL =  '/dev/'

export const callFrankyData = async ( {commit, rootState  } ) => {
    try{
        commit( 'LOADING_FRANKY', true )
        const token    = await rootState.Token.token.dataObj
        const {data}   = await axios.request({
            url:'/json/franky.json',
            method:'get',
            baseURL : URL,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
                },
        })
        commit( 'LOADING_FRANKY', false )
        commit( 'SET_FRANKY' , data)
    } catch( err ){
        commit( 'LOADING_FRANKY', false )
        commit( 'ERROR_FRANKY', err.message )
    }
}