export default{
    navbar: {},
    loading: false,
    error: "",
    isOpenMenu: false,
    bodyOverflow: 'auto',
    isShowFooter: true,
    currentMediaQuery: null,
    isRegisterPage: false,
    scrollPosition: null,
    modal: {
        isShowModal: false,
        mobileBodyHeight: 0,
        start: false,
        stop: true
    }, 
}