import axios from 'axios'
import {endpoints} from '@/api/endpoints.js';
import { baseURL } from '../../api/environment';

const URL =  baseURL
export const callAllPages= async ( {commit, rootState  }, slug ) => {
    try{
        const endpoint = endpoints.page.getPageInfo.endpoint+`${slug}`
        commit( 'LOADING_PACKAGES', true )
        const token    = await rootState.Token.token.dataObj
        console.log( endpoint )
        const {data}   = await axios.request({
            url:`${endpoint}`,
            method:'get',
            baseURL : URL,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
                },
        })
        if( data.dataObj.length == 0 ){
            return false
        } else{
            commit( 'SET_ALL_PAGES' , data.dataObj)
            commit( 'SET_PAGE_NUMBER', slug )
            return true
        }

    } catch( err ){
        commit( 'LOADING_PACKAGES', false )
        commit( 'ERROR_PACKAGES', err.message )
    } finally {
        commit( 'LOADING_PACKAGES', false )

    }
}

export const callPreviewPages= async ( {commit, rootState  }, slug ) => {
    try{
            const endpoint = endpoints.page.getPreviewModule.endpoint+`${slug}`
            commit( 'LOADING_PREVIEW', true )
            const token    = await rootState.Token.token.dataObj
            const {data}   = await axios.request({
                url:`${endpoint}`,
                method:'get',
                baseURL : URL,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': token
                    },
            })
            if( data.dataObj.length == 0 ){
                return false
            } else{
                commit( 'SET_PREVIEW_MODULE' , data.dataObj.information)
                return true
            }
        
    } catch( err ){
        commit( 'ERROR_PREVIEW', err.message )
    } finally {
        commit( 'LOADING_PREVIEW', false )

    }
}