<template>
<div >
    <div class="heading_separator w-2/12"></div>
    <div class="text-center">
        <h2><Skeletor width="50%" /></h2>
    </div>
    <div class="flex justify-center align-middle items-center mt-3 w-6/12 m-auto ">
        <Skeletor width="100%" height="500px" v-for="index in 3" :key="index" class="mr-3 bg-red"/>
    </div>
</div>
</template>
<script>
import { Skeletor } from 'vue-skeletor';

export default {
    components:{
        Skeletor,
    }
}
</script>