import axios from 'axios'
import {endpoints} from '@/api/endpoints.js';
import { baseURL } from '../../api/environment';
const URL =  baseURL

export const callHomeData = async ( {commit, rootState  }, pageNumber) => {
    try{
        const endpoint = endpoints.home.getHomeData.endpoint+`${pageNumber.value}`
        
        commit( 'LOADING_HOME', true )
        const token    = await rootState.Token.token.dataObj
        const {data}   = await axios.request({
            url:`${endpoint}`,
            method:'get',
            baseURL : URL,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
                },
        })
        commit( 'LOADING_HOME', false )
        commit( 'SET_HOME_DATA' , data)
    } catch( err ){
        commit( 'LOADING_HOME', false )
        commit( 'ERROR_FOOTER', err.message )
    }
}


export const callPackagesHomeData = async ( {commit, rootState  }, pageNumber ) => {
    try{
        const endpoint = endpoints.home.getHomeData.endpoint+pageNumber 
        commit( 'LOADING_HOME', true )
        const token    = await rootState.Token.token.dataObj
        const {data}   = await axios.request({
            url:`${endpoint}`,
            method:'get',
            baseURL : URL,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
                },
        })
        commit( 'LOADING_HOME', false )
        commit( 'SET_PACKAGES_DATA' , data)
    } catch( err ){
        commit( 'LOADING_HOME', false )
        commit( 'ERROR_FOOTER', err.message )
    }
}