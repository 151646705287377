export const SET_PACKAGE = ( state, payload ) => {
    state.selected = payload 
}

export const SET_PACKAGES_DATA = ( state, payload ) => {
    state.products = payload 
}

export const SET_PACKAGES_INFORMATION = ( state, payload ) => {
    if ( payload ){
        state.information = payload 
        localStorage.setItem('SET_PACKAGES_INFORMATION', JSON.stringify(payload) )
    } else {
        localStorage.removeItem('SET_PACKAGES_INFORMATION');
    }
    
}
export const LOADING_PACKAGES = ( state, boolean ) => {
    state.loading =  boolean
}

export const ERROR_PACKAGES = ( state, message ) => {
    state.error =  message
}
export const SET_ON_COVERAGE = ( state, payload ) => {
    state.on_coverage =  payload
}
export const SET_LOGOS =  ( state, payload ) => {
    state.logos =  payload
}