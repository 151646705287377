export const getNavbarData = ( state) => {
    return state.navbar
}
export const getNavbarList = ( state ) => {
    return state.navbar.dataObj
}
export const getBodyOverflow = ( state ) => {
    return state.bodyOverflow
}
//------------------------------------
export const getModal = ( state ) => {
    return state.modal
}
export const GET_ISSHOWMODAL = ( state ) => {
    return state.modal.isShowModal 
}
export const GET_DESKTOP_MODAL_SIZE = ( state ) => {
    return state.modal.modalSize
}
export const GET_ISSHOWFOOTER = ( state ) => {
    return state.isShowFooter 
}
export const GET_MOBILE_BODY_HEIGHT = ( state ) => {
    if(state.currentMediaQuery == 'xs' || state.currentMediaQuery == 'sm' || state.currentMediaQuery == 'md') {
        if(state.isRegisterPage == true){
            return state.modal.mobileBodyHeight ? `height:${state.modal.mobileBodyHeight}px; overflow:initial; ` : ''  
        }
        return state.modal.mobileBodyHeight ? `height:${state.modal.mobileBodyHeight}px; overflow:auto;` : ''   
    }
    else 
    return     
}
