<template>
    <div class="flex justify-end page_registro _details xs:h-full sm:h-full" >
        <template v-if="loading">
            <DetailsLoader />
        </template>
        <template v-else>
            <div class="bg-gray-250 py-5 px-10 rounded-xl details_container xs:rounded-none sm:rounded-none" v-if="information">
                <h3 class="text-red uppercase text-2xl">Detalles de tu paquete</h3>
                <div class="separator_form"></div>
                <div class="w-full">
                    <p> Paquete de Televisión </p>
                    <div class="flex rounded-3xl justify-between  bg-gray-150  px-5 py-4 my-2 items-center max-h-13">
                        <p class="text-red">{{ information.nombre }} </p>
                        <p class="text-darkgray-400"> {{parseFloat(information.precio).toFixed(2)}} /mes</p>
                    </div>
                </div>
                <div class="separator_form" style="margin-top:20px;"></div>
                <div class="w-full">
                    <div class="flex  justify-between my-3" v-if="paymentMethod != 'efectivo' && paymentMethod != 'paypal'">
                        <p class="font-medium">Costo Mensual:</p>
                        <div class="text-right">
                            <p>${{information.precio }}</p>
                            <!-- <a href="#" class="text-red text-xs underline"> Ahorras $99.00 / mes </a>  -->
                        </div>
                    </div>
                    <div class="flex  justify-between my-3" v-if="paymentMethod == 'efectivo' || paymentMethod == 'paypal'">
                        <p class="font-medium">Costo Mensual:</p>
                        <div class="text-right">
                            <p>${{information.precio_efectivo }}</p>
                            <!-- <a href="#" class="text-red text-xs underline"> Ahorras $99.00 / mes </a>  -->
                        </div>
                    </div>
                    <div class="flex  justify-between my-3">
                        <p class="font-medium">Gastos de Instalación:</p>
                        <div class="text-right"  v-if="paymentMethod == 'efectivo' || paymentMethod == 'paypal'">
                            <p> ${{ parseFloat(effectivoPrice).toFixed(2) }}</p>
                            <!-- <p>Pago único $1.00</p>
                            <a href="#" class="text-red text-xs underline"> Ahorras $99.00 </a>  -->
                        </div>
                        <div class="text-right"  v-if="paymentMethod != 'efectivo' && paymentMethod != 'paypal'">
                            <p> ${{ parseFloat(information.suscripcion).toFixed(2) }}</p>
                            <!-- <p>Pago único $1.00</p>
                            <a href="#" class="text-red text-xs underline"> Ahorras $99.00 </a>  -->
                        </div>
                    </div>
                    <div class="flex justify-between my-3">
                        <p class="font-medium text-2xl" v-if="paymentMethod != 'efectivo' && paymentMethod != 'paypal'">Total a pagar</p>
                        <p class="text-2xl"> ${{ parseFloat( parseInt(information.suscripcion) ).toFixed(2) }} </p>
                    </div>
                    <div class="flex justify-between my-3" v-if="paymentMethod == 'efectivo' || paymentMethod == 'paypal'">
                        <p class="font-medium text-2xl">Total a pagar</p>
                        <p class="text-2xl"> ${{ parseFloat( parseInt(effectivoPrice) ).toFixed(2) }} </p>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import { useRoute, useRouter } from 'vue-router'
import { mapGetters, mapState, useStore } from 'vuex'
import DetailsLoader from '@/components/loaders/Details.vue'
export default {
    components: { DetailsLoader },
    computed:{
        ...mapState( {
            loading : state => state.Products.loading
        }),
        ...mapGetters({
            information : 'getProductInformation'
        })
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const route = useRoute()
        const packagesData =  store.state.Home.packages.dataObj
        let packageInfo = store.state.Products.selected

        const checkRoute = async () => {
            if( route.name == 'RegisterCode'){
                    if(!packagesData ){
                        store.state.Products.loading =  true

                        await store.dispatch( 'callPackagesHomeData', 16 )
                        const packageList  = await store.getters.getPackageDataList.list
                              packageInfo = packageList.find( ( data ) => {  return data.package.erp_id == route.params.package_code  } ) 
                        if( packageInfo ){
                            store.state.Products.loading =  false 
                            store.dispatch('setProduct', packageInfo.package)
                            store.dispatch('callPackageInformation', packageInfo.package.package_id )
                        } else {
                            store.state.Products.loading =  false
                            router.push( { name : 'Home'}) 
                        }
                    } 
            } else {
                if( Object.keys(packageInfo).length == 0 ) {
                  router.push( { name : 'Home'}) 
                } else {
                    store.dispatch('callPackageInformation', packageInfo.package_id )
                }
            }
        }
        checkRoute();
        return { packageInfo  }
    },
}
</script>
<style scoped>
    .page_registro._details p {
        @apply font-medium;
    }
    div.w-full {
        @apply mt-5;
    }
    .details_container {
        width: 586px;
        @apply md:w-full lg:w-full
    }
    @media screen and (max-width:767px) {
        .page_registro._details {
            min-height: 100vh;
        }        
    }
    @media screen and (min-width:768px) and (max-width:991px) {
        .page_registro._details  {
            min-height: auto;
        }
    }
</style>