<template>
<div class="mt-8 xs:mt-0 sm:mt-0" v-show = "isShowFooter">
    <FooterLoader v-if="loading" />
    <footer class="bg-black" v-if="!loading">
        <div class="footer xs:px-6 sm:px-6 md:px-6 pt-7 px-8 xs:py-10 mx-auto xs:pb-5 sm:pb-5 lg:container xl:container 2xl:container">
            <p class="text-2xl text-white pb-px-6 xs:text-center xs:text-xl sm:text-center md:text-center sm:text-xl"> Contrataciones 
                    <a :href="'tel:'+tel.tel_prin.replace(/ /g,'')" target="_system" class="font-medium"> {{tel.tel_prin}}</a>
                    <span class="xs:hidden sm:hidden md:hidden lg:visible xl:visible 2xl:visible">|</span>  Atención a Clientes
                      <a :href="'tel:'+tel.tel_second.replace(/ /g,'')"   target="_system" class="font-medium" v-if="tel">
                        {{tel.tel_second}}
                    </a>
            </p>
            <div class="flex xs:flex-col sm:flex-col">
                <div class="bg-gradient-to-r md:w-4/12 md:flex p-5 md:flex-col md:justify-center from-gray-850 to-gray-800 rounded-lg xs:flex-none py-5 xs:from-darkgray-200 xs:to-darkgray-200 flex-auto">
                    <img src="@/assets/images/logo-ON_light.png" alt="ON" class="xs:mx-auto sm:mx-auto md:mx-auto md:max-h-10">
                    <div class="flex xs:flex-col sm:flex-col md:flex-col" >
                        <h2 class="text-2xl text-white my-5 text-left font-thin flex-1 xs:text-center xs:text-xl sm:text-center md:text-center sm:text-xl " v-if="bannner">{{ bannner.text }}</h2>
                        <div class="flex-1 flex items-center xs:justify-center sm:justify-center md:justify-center justify-end" v-if="bannner">
                            <a :href="bannner.link_external" class="btn-green text-center" target="_blank" rel="noreferrer" v-if="bannner.type_link == 'E' "> Da click aquí</a>
                            <a @click.prevent="setPageNumber( bannner.page_internal )" href="#" class="btn-green text-center" rel="noreferrer" target="_blank"  v-if="bannner.type_link == 'I' "> Da click aquí</a>
                        </div>
                    </div>
                </div>
                <div class="flex mx-8 xs:mx-2 xs:my-5 items-end xs:flex-col sm:flex-col flex-auto md:flex-col" >
                    <div class="flex flex-col xs:mx-auto xs:w-full sm:mx-auto sm:w-full md:w-full">
                        <p class="text-white xs:text-center xs:text-darkgray-300 sm:text-center sm:text-darkgray-300 sm:pt-5 md:pl-3">Descarga nuestra App</p>
                        <ul class="flex items-center justify-start mt-2 xs:mx-auto xs:hidden sm:hidden" v-if="applinks">
                            <li class="m-1" v-for="( link , idx ) in applinks " :key="idx"> 
                                <a :href="link.addres">
                                    <img :src="link.icon"   :alt="link.icon.split(/_/)[1].split('.')[0]"  class="xs:hidden sm:hidden md:visible lg:visible xl:visible 2xl:visible" v-if="link.icon">
                                    <img :src="link.icon_phone" :alt="link.icon.split(/_/)[1].split('.')[0]" class="xs:visible sm:visible md:hidden lg:hidden xl:hidden 2xl:hidden"  v-if="link.icon_phone">
                                </a>
                                
                            </li>
                        </ul>
                        <ul class=" items-center justify-center mt-2 md:w-full xs:mx-auto xs:visible hidden xs:block text-center sm:mx-auto sm:visible sm:block " v-if="applinks">
                             <li  v-for="( link , idx ) in applinks " :key="idx" class="inline-block mt-2"> 
                                <a :href="link.addres">
                                    <img :src="link.icon" class="xs:hidden sm:hidden md:visible lg:visible xl:visible 2xl:visible" :alt="link.icon.split(/_/)[1].split('.')[0]"  v-if="link.icon">
                                    <img :src="link.icon_phone" :alt="link.icon_phone.split(/_/)[1].split('.')[0]" class="xs:visible sm:visible md:hidden lg:hidden xl:hidden 2xl:hidden"  v-if="link.icon_phone">
                                </a>
                            </li>
                        </ul>
                        
                        <ul class="text-white md:w-full m-2 xs:text-center xs:text-darkgray-300 xs:mb-0 xs:mt-4 sm:text-center sm:text-darkgray-300 sm:mb-0 sm:mt-4 " v-if="leftlinks">
                            <li v-for="( link , idx ) in leftlinks " :key="idx"> 
                                <a :href="link.url_external" target="_blank" rel="noreferrer" v-if="link.type_link == 'E'">{{link.text }}</a>
                                <a @click.prevent="setPageNumber( link.url_internal )" rel="noreferrer" href="#" target="_blank" v-if="link.type_link == 'I'">{{link.text }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="flex xs:text-center xs:flex-none md:w-full xs:mx-auto sm:flex-none sm:mx-auto sm:text-center pl-10 xs:pl-0 sm:pl-0 md:pl-0">
                        <ul class="text-white m-2 xs:text-darkgray-300 xs:mt-0  sm:text-darkgray-300 sm:mt-0 " v-if="rightlinks">
                            
                            <li v-for="( link , idx ) in rightlinks " :key="idx"> 
                                <a :href="link.url_external" rel="noreferrer" target="_blank" v-if="link.type_link == 'E'" :aria-label="link.text">{{link.text }} </a>
                                <a @click.prevent="setPageNumber( link.url_internal )" rel="noreferrer" href="#" target="_blank" v-if="link.type_link == 'I'" :aria-label="link.text">{{link.text }}</a>
                                 <a href="#" v-if="link.type_link == null " :aria-label="link.text">{{link.text }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="flex items-center justify-between py-px-6 xs:flex-col xs:pt-4 xs:pb-0 xs:border-solid xs:border-darkgray-300 xs:border-t sm:flex-col">
                <p class="text-gray-400 xs:text-center xs:text-xl xs:px-5 xs:mb-2 sm:text-center sm:text-xl sm:px-5 sm:mb-2">Conoce a los miembros de nuestra familia </p>
                <ul class="flex items-center ml-28 xs:ml-0 sm:ml-0" v-if="services">  
                     <li class="mx-1" v-for="( link , idx ) in services " :key="idx"> 
                        <a :href="link.url_external" v-if="link.type_link =='E'">
                            <img :src="link.icon" :alt="link.icon.split(/_/)[1].split('.')[0]" :aria-label="link.text" v-if="link.icon">
                        </a>
                        <a @click.prevent="setPageNumber( link.url_internal )" href="#" v-if="link.type_link =='I'">
                            <img :src="link.icon" :alt="link.name" :aria-label="link.text" v-if="link.icon">
                        </a> 
                    </li>
                </ul>
                 <ul class="flex items-center xs:hidden xs:mx-auto sm:hidden sm:mx-auto" v-if="social"> 
                    <li class="mx-1" v-for="( link , idx ) in social " :key="idx"> 
                        <a :href="link.link_dest"> <img :src="link.icon" :alt="link.icon.split(/_/)[1].split('.')[0]" :aria-label="link.text"  v-if="link.icon"> </a>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
    <div class=" bg-gray-800" v-if="!loading">
        <p class="footer_legal px-8 lg:container xl:container 2xl:container 2xl:mx-auto text-gray-400 py-8 xs:text-left xs:py-4 xs:p-6 sm:text-left  sm:py-4 sm:p-6 md:p-6 md:py-6" v-if="legals" v-html="legals.information">
        </p>
    </div>
</div>
</template>
<style>
   
</style>
<script>
import { mapGetters, mapState, useStore } from 'vuex'
import FooterLoader from '@/components/loaders/Footer.vue'
export default {
    computed: {
        ...mapGetters({
            footer: 'getFooterData',
            tel : 'getFooterTelData',
            applinks : 'getFooterAppLinks',
            leftlinks : 'getFooterLeftLinks',
            rightlinks : 'getFooterRightLinks',
            legals : 'getFooterLegals',
            bannner: 'getFooterBannerProm', 
            social: 'getFooterSocialLinks',
            services: 'getFooterService',
            isShowFooter: 'GET_ISSHOWFOOTER'
        }),
        ...mapState({
            loading : state => state.Footer.loading
        })
    },
    components: { FooterLoader },
    setup( ){
        const store = useStore()
        const setPageNumber = ( pageNumber ) =>  {
            store.commit( 'SET_PAGE_NUMBER', pageNumber )
            window.scroll(0,0)
        }

        store.dispatch( 'callNavbarData' )
        store.dispatch( 'callFooterData' )

        return { setPageNumber }
    }
}
</script>