import axios from 'axios'
import {endpoints} from '@/api/endpoints.js';
import { baseURL } from '../../api/environment';

const URL =  baseURL

export const callNavbarData = async ( {commit, rootState } ) => {
    try{
        const endpoint = endpoints.navbar.getNavbar.endpoint
        commit( 'LOADING_NAVBAR', true )
        const token    = await rootState.Token.token.dataObj
        const {data}   = await axios.request({
            url:`${endpoint}`,
            method:'get',
            baseURL : URL,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
                },
        })

        commit( 'LOADING_NAVBAR', false )
        commit( 'SET_NAVBAR_DATA' , data)
    } catch( err ){
        commit( 'LOADING_NAVBAR', false )
        commit( 'ERROR_NAVBAR', err.message )
    }
}
