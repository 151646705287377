import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Maska from 'maska'
import 'typeface-inter';
import './config/axios'
import './assets/css/index.css'
import VueGoogleMaps from '@fawmi/vue-google-maps'

import { Vue3Mq } from "vue3-mq";
import Vue3TouchEvents from "vue3-touch-events";

const isMobile = () =>
	/iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(
		navigator.userAgent.toLowerCase()
	);
  
const app = createApp(App)
      app.directive('maska', Maska.maska)
      app.use(store)
      app.use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyBftiC3hfNeseWaeLlVU1ftoKAfSWUFCkQ',
            libraries: "places"
        },
      })
      if(isMobile()) 
      app.use(Vue3TouchEvents)
      app.use(Vue3Mq)
        .use(router)
        .mount('#app');


