export const getProducts = ( state) => {
    return state.products
}
export const getProductsList = ( state ) => {
    return state.products.dataObj
}
export const getProductInformation =  ( state ) => {
    const local = localStorage.getItem('SET_PACKAGES_INFORMATION');
    return  ( local ? JSON.parse(local) :  state.information )
  }
export const getLogosCategories = ( state ) => {
    const logos  =  state.logos
    let normCat = []
    if ( logos ){
    let categories = []
    if( logos.canalesDM  ){
        logos.canalesDM.forEach(element => {
            element.categorias.forEach( data =>{
                if( categories.indexOf(data) == -1) {
                    categories.push(data)
                }
            });
        });

        categories.forEach( data => {
            let list = logos.canalesDM.filter( ({categorias}) => categorias.indexOf(data) != -1 )
                normCat.push( { 
                    category : data,
                    data : list } )
            })
    }


    if( logos.canalesAbiertaHD  ){
        logos.canalesAbiertaHD.forEach(element => {
            element.categorias.forEach( data =>{
                if( categories.indexOf(data) == -1) {
                    categories.push(data)
                }
            });
        });

        categories.forEach( data => {
            let list = logos.canalesAbiertaHD.filter( ({categorias}) => categorias.indexOf(data) != -1 )
                normCat.push( { 
                    category : data,
                    data : list } )
            })
    }

    if( logos.canalesDish  ){
        logos.canalesDish.forEach(element => {
            element.categorias.forEach( data =>{
                if( categories.indexOf(data) == -1) {
                    categories.push(data)
                }
            });
        });

        categories.forEach( data => {
            let list = logos.canalesDish.filter( ({categorias}) => categorias.indexOf(data) != -1 )
                normCat.push( { 
                    category : data,
                    data : list } )
            })
    }

    if( logos.canalesAbierta ){
        logos.canalesAbierta.forEach(element => {
            element.categorias.forEach( data =>{
                if( categories.indexOf(data) == -1) {
                    categories.push(data)
                }
            });
        });

        categories.forEach( data => {
            let list = logos.canalesAbierta.filter( ({categorias}) => categorias.indexOf(data) != -1 )
                normCat.push( { 
                    category : data,
                    data : list } )
            })
    }
    return normCat

} else {
    return normCat

}

    

}