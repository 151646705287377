<template>
  <div class="home" :style="mobileBodyHeight">
    <Header />
    <div ref="bodyRef_" class="mx-auto my-5 xs:pb-24 sm:pb-24 md:px-6 xs:my-0 xs:mx-0 md:mx-0 md:container 2xl:container xl:container lg:container xs:w-full sm:w-full xl:p-10 relative xs:flex sm:flex xs:flex-col sm:flex-col">
        <div>
            <div class="separator"></div>
            <h1> Encuentra TU PAQUETE IDEAL </h1>
        </div>
    </div>
    <Footer /> 
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'Home',
  components: {
    Header,
    Footer,
  },
}
</script>